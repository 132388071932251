import React from "react";
import styles from "./CategoryCard.module.scss";
import { useNavigate } from "react-router-dom";
import { navigateToCategory, navigateToSubcategory } from "../../app/routes/routingHelper";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import ButtonComponent from "../buttonComponent/ButtonComponent";
import routes from "../../app/routes/routes";

const CategoryCard = ({ name, label, image, isSubCategory = false, makeButtonClickable }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const onClickHandler = () => {
    if (!isSubCategory) {
      navigateToCategory(navigate, name);
    } else {
      navigateToSubcategory(navigate, name);
    }
  };

  const containerProps = {};
  const buttonProps = {};

  if (makeButtonClickable) {
    if (!isSubCategory) {
      buttonProps.destination = routes.categories.id.replace(":categoryName", name);
    } else {
      buttonProps.action = onClickHandler;
    }
  } else {
    containerProps.onClick = onClickHandler;
  }

  return (
    <div
      className={clsx(styles["category-card-container"], makeButtonClickable && styles["show"])}
      {...containerProps}
    >
      <div className={styles["category-card"]} /*style={{ backgroundImage: `url(${image})` }}*/ />
      <div className={styles["text-container"]}>
        <p className={styles.heading}>{label}</p>
        <span className={styles["button-container"]}>
          <ButtonComponent styleClass={"btn-bold"} text={t("general.see_more")} {...buttonProps} />
        </span>
      </div>
    </div>
  );
};

export default CategoryCard;
