import React from "react";
import { BrowserRouter } from "react-router-dom";
import "../../styles/globals.scss";
import Layout from "../Layout/Layout";
import "../../translations/i18n.js";
import { store } from "../../store/Store";
import { Provider } from "react-redux";
import ScrollToTop from "../../features/scrollToTop";

const App = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <ScrollToTop />
        <Layout />
      </BrowserRouter>
    </Provider>
  );
};

export default App;
