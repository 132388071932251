import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./ContactSectionComponent.module.scss";
import IconText from "../iconText/IconText";

import { BsCalendar3 } from "react-icons/bs";
import { HiPhone } from "react-icons/hi";
import { MdEmail } from "react-icons/md";

const ContactSectionComponent = () => {
  const { t } = useTranslation();

  const iconsList = [
    //TODO: This is a temporary list as we wait for information from the client
    {
      icon: HiPhone,
      texts: ["contact.phone_number", "contact.mobile_number", "contact.fax_number"],
    },
    { icon: MdEmail, texts: ["contact.email1", "contact.email2"] },
    { icon: BsCalendar3, texts: ["general.schedule"] },
  ];

  return (
    <div>
      <p className={styles["paragraph"]}>{t("contact.contact_details")}</p>
      <IconText icons={iconsList} styleClass="contact-page" />
    </div>
  );
};

export default ContactSectionComponent;
