import React from "react";
import routes from "../../app/routes/routes";
import styles from "./AboutUs.module.scss";
import { useTranslation } from "react-i18next";
import ButtonComponent from "../buttonComponent/ButtonComponent";
import ImageComponent from "../imageComponent/ImageComponent";

const AboutUs = ({ title, text }) => {
  const { t } = useTranslation();

  const imagesList = [
    {
      image: require("../../assets/images/tool.jpg"),
      description: t("components.images.image_description1"),
    },
    {
      image: require("../../assets/images/wood.png"),
      description: t("components.images.image_description2"),
    },
  ];

  return (
    <div className={styles["about-container"]}>
      <div className={styles["about-content"]}>
        <h2 className={styles["about-title"]}> {title} </h2>

        <p className={styles["about-text"]}>{text}</p>
        <div className={styles["about-button"]}>
          <ButtonComponent destination={routes.aboutUs} text={t("components.about_us.button")} />
        </div>
      </div>
      <div className={styles["about-images"]}>
        <ImageComponent images={imagesList} isRounded={true} />
      </div>
    </div>
  );
};

export default AboutUs;
