import React, { useState } from "react";
import { isMobile } from "react-device-detect";
import clsx from "clsx";

import styles from "./FlipCard.module.scss";

const Card = ({ label, values }) => {
  const [isFlipped, setIsFlipped] = useState(false);

  if (!label || label === "" || !values || values === "") {
    return null;
  }

  return (
    <div
      className={clsx(styles["flip-card-container"], isFlipped && styles.flipped)}
      onClick={() => isMobile && setIsFlipped(!isFlipped)}
    >
      <div className={styles["flip-card-content"]}>
        <div className={clsx(styles.card, styles["front-card"])}>
          <p>{label}</p>
        </div>
        <div className={clsx(styles.card, styles["back-card"])}>
          <ul>
            {values.split(";").map((value, index) => {
              return <li key={`flip-card-value-${index}`}>{value}</li>;
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

const FlipCard = ({ data }) => {
  if (!data.length) {
    return null;
  }

  return (
    <div className={styles["cards-container"]}>
      {data.map((card, index) => {
        return <Card label={card.label} values={card.values} key={`${card.label}-${index}`} />;
      })}
    </div>
  );
};

export default FlipCard;
