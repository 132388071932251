import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const sirtisaApi = createApi({
  reducerPath: "sirtisaApi",
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  tagTypes: ["Category, Subcategory, Product"],
  endpoints: builder => ({
    categoriesAndSubcategories: builder.query({
      query: () => "/sirtisa-categories/categories-and-subcategories",
      providesTags: ["Category, Subcategory"],
    }),
    categories: builder.query({
      query: () => "/sirtisa-categories/",
      providesTags: ["Category"],
    }),
    subcategories: builder.query({
      query: categoryName => `/sirtisa-subcategories/${categoryName}`,
      providesTags: ["Subcategory"],
    }),
    subcategory: builder.query({
      query: subcategoryName => `/sirtisa-subcategories/subcategory/${subcategoryName}`,
      providesTags: ["Subcategory"],
    }),
    products: builder.query({
      query: arg => {
        const { subcategoryName, hasNoSubcategories } = arg;
        return {
          url: `/sirtisa-products/${subcategoryName}/${hasNoSubcategories}`,
          params: { subcategoryName, hasNoSubcategories },
          providesTags: ["Product"],
        };
      },
    }),
    searchProducts: builder.query({
      query: searchQuery => `/sirtisa-products?searchQuery=${searchQuery}`,
    }),
    images: builder.query({
      query: arg => {
        const { id, type } = arg;
        return {
          url: `/images/${id}/${type}`,
          params: { id, type },
        };
      },
    }),
    completeFormContact: builder.mutation({
      query: mail => ({
        url: "/contact",
        method: "POST",
        body: mail,
      }),
    }),
  }),
});

export const {
  useCategoriesAndSubcategoriesQuery,
  useCategoriesQuery,
  useSubcategoriesQuery,
  useSubcategoryQuery,
  useProductsQuery,
  useSearchProductsQuery,
  useImagesQuery,
  useCompleteFormContactMutation,
} = sirtisaApi;
