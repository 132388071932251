import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isOpen: false,
};

const menuButtonSlice = createSlice({
  name: "menuButton",
  initialState,
  reducers: {
    openOrCloseMenu: (state, action) => {
      state.isOpen = action.payload;
    },
  },
});

export const { openOrCloseMenu } = menuButtonSlice.actions;

export default menuButtonSlice.reducer;
