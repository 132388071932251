import { useTranslation } from "react-i18next";
import React from "react";
import { isMobile } from "react-device-detect";

import { BsClock } from "react-icons/bs";
import { GrDropbox } from "react-icons/gr";
import { RiTruckLine } from "react-icons/ri";
import { TbAward } from "react-icons/tb";

import Zone from "../../components/zone/Zone";
import CategoriesMenu from "../../components/lateralMenu/CategoriesMenu";
import Slider from "../../components/slider/Slider";
import IconText from "../../components/iconText/IconText";
import AboutUs from "../../components/aboutUs/AboutUs";
import FeaturedCategories from "../../components/featuredCategories/FeaturedCategories";
import PartnersSlider from "../../components/partnersSlider/PartnersSlider";
import FeaturedProductsSlider from "../../components/featuredProductsSlider/FeaturedProductsSlider";

import Product1 from "../../assets/images/recommended_products/produs_recomandat_1.jpeg";
import Product2 from "../../assets/images/recommended_products/produs_recomandat_2.jpeg";
import Product3 from "../../assets/images/recommended_products/produs_recomandat_3.jpeg";
import Product4 from "../../assets/images/recommended_products/produs_recomandat_4.jpeg";
import Product5 from "../../assets/images/recommended_products/produs_recomandat_5.jpeg";
import Product6 from "../../assets/images/recommended_products/produs_recomandat_6.jpeg";
import SeoComponent from "../../components/seoComponent/SeoComponent";

//TODO: get favorite products from API
const productsList = [
  {
    image: Product1,
    name: "Bochemit Opti F",
  },
  {
    image: Product2,
    name: "Bochemit Antiflash",
  },
  {
    image: Product3,
    name: "Bochemit Forte Profi",
  },
  {
    image: Product4,
    name: "Bochemit Antimucegai",
  },
  {
    image: Product5,
    name: "Bochemit Plus I",
  },
  {
    image: Product6,
    name: "Bochemit Bluestop M",
  },
];

const Home = () => {
  const { t } = useTranslation();

  const iconsList = [
    //TODO: This is a temporary list as we wait for information from the client
    { icon: BsClock, texts: ["components.icon_text.icon_description1"] },
    { icon: GrDropbox, texts: ["components.icon_text.icon_description2"] },
    { icon: RiTruckLine, texts: ["components.icon_text.icon_description3"] },
    { icon: TbAward, texts: ["components.icon_text.icon_description4"] },
  ];

  return (
    <div>
      <SeoComponent
        title="Sirtisa - Produse pentru protectia și tratarea lemnului la interior și exterior"
        description="Cu o experiență de peste 25 de ani în domeniu, Sirtisa furnizează produse și soluții
      pentru protectia și tratarea lemnului. Contactează-ne pentru detalii!"
      />
      <Zone>
        <CategoriesMenu />
        <Slider isHomepage={true} />
      </Zone>
      <Zone>
        <AboutUs
          title={t("components.about_us.title")}
          text={t("components.about_us.description")}
        />
      </Zone>
      <Zone>
        <FeaturedProductsSlider productsData={productsList} />
      </Zone>
      <Zone>
        <IconText icons={iconsList} heading={t("components.icon_text.heading")} />
      </Zone>
      <Zone>
        <FeaturedCategories makeButtonClickable={!isMobile && true} />
      </Zone>
      <Zone>
        <PartnersSlider />
      </Zone>
    </div>
  );
};

export default Home;
