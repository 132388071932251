const replaceUnderscore = element => {
  return element
    .split("_")
    .map((word, index) => {
      if (index === 0) {
        return `${word.slice(0, 1).toUpperCase()}${word.slice(1)}`;
      }
      return word;
    })
    .join(" ");
};

export default replaceUnderscore;
