import React from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import Input from "../input/Input";
import Select from "../select/Select";
import styles from "./Form.module.scss";
import { useFormik } from "formik";
import { useCompleteFormContactMutation } from "../../features/api/sirtisaApi";

const contactSchema = yup.object().shape({
  userName: yup.string().required(),
  phoneNumber: yup.number().required(),
  email: yup.string().email().required(),
  message: yup.string().required(),
});

const Form = () => {
  const { t } = useTranslation();
  const [completeFormContact, data] = useCompleteFormContactMutation();
  const { values, handleChange, errors, touched, handleSubmit, resetForm } = useFormik({
    initialValues: {
      userName: "",
      phoneNumber: "",
      email: "",
      selectSubject: "Suport",
      message: "",
    },
    validationSchema: contactSchema,
    onSubmit: values => {
      completeFormContact(values);
      resetForm({ values: "" });
    },
  });

  return (
    <>
      <div className={styles["form-container"]}>
        <p className={styles["contact-us"]}>{t("components.form.text")}</p>
        <form className={styles.form} onSubmit={handleSubmit}>
          <Input
            inputType="text"
            name="userName"
            value={values.userName}
            onChange={handleChange}
            inputPlaceholder={t("components.form.name")}
            styleClass={"form-input"}
          />
          {errors.userName && touched.userName && (
            <span className={styles["errors"]}>{t("components.form.errors.user-name")}</span>
          )}
          <Input
            inputType="text"
            name="phoneNumber"
            value={values.phoneNumber}
            onChange={handleChange}
            inputPlaceholder={t("components.form.phone-number")}
            styleClass={"form-input"}
          />
          {errors.phoneNumber && touched.phoneNumber && (
            <span className={styles["errors"]}>{t("components.form.errors.phone-number")}</span>
          )}
          <Input
            inputType="text"
            name="email"
            value={values.email}
            onChange={handleChange}
            inputPlaceholder={t("components.form.email")}
            styleClass={"form-input"}
          />
          {errors.email && touched.email && (
            <span className={styles["errors"]}>{t("components.form.errors.email")}</span>
          )}
          <Select
            options={["Suport", "Vanzari", "Reclamatii"]}
            onChange={handleChange}
            name="selectSubject"
            value={values.selectSubject}
            styleClass={"select-subject"}
          />
          <textarea
            placeholder={t("components.form.message")}
            onChange={handleChange}
            name="message"
            value={values.message}
            className={styles["form-message"]}
          />
          {errors.message && touched.message && (
            <span className={styles["errors"]}>{t("components.form.errors.message")}</span>
          )}
          <div className={styles["form-button-container"]}>
            <Input
              inputType="submit"
              name="submit"
              inputPlaceholder={
                data.isLoading ? t("components.form.sending") : t("components.form.send-message")
              }
              styleClass={"form-input"}
            />
            {data.isSuccess && <p>{t("components.form.message_sent")}</p>}
            {data.isError && <p>{t("components.form.submit_error")}</p>}
          </div>
        </form>
      </div>
    </>
  );
};

export default Form;
