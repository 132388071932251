import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import clsx from "clsx";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";

import styles from "./FeaturedProductsSlider.module.scss";
import ProductCard from "../productCard/ProductCard";

const initialIndexes = arrayLength => {
  return Array.from({ length: arrayLength }, (element, index) => index);
};

const FeaturedProductsSlider = ({ productsData }) => {
  const { t } = useTranslation();
  let show;

  if (isMobile && window.innerWidth < 767) {
    show = 1;
  } else {
    show = 3;
  }

  const [currentIndexes, setCurrentIndexes] = useState(initialIndexes(show));

  const nextProduct = () => {
    if (currentIndexes[currentIndexes.length - 1] < productsData.length - 1) {
      setCurrentIndexes(currentIndexes.map(el => ++el));
    } else {
      setCurrentIndexes(initialIndexes(show));
    }
  };

  const prevProduct = () => {
    if (currentIndexes[0] === 0) {
      const partnersLength = productsData.length - 1;
      const newIndexes = [];

      for (let i = 0; i < show; i++) {
        newIndexes.push(partnersLength - i);
      }
      setCurrentIndexes(newIndexes.reverse());
    } else {
      setCurrentIndexes(currentIndexes.map(el => --el));
    }
  };

  const timeoutRef = useRef(null);
  const sliderTimer = 5000;

  const resetTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(() => nextProduct(), sliderTimer);

    return () => {
      resetTimeout();
    };
  }, [currentIndexes]);

  return (
    <div className={styles["container-slider"]}>
      <h2 className={styles.heading}>{t("components.featured_products_slider.heading")}</h2>
      <div className={styles.products}>
        {productsData.map((product, index) => (
          <ProductCard
            styleClass={currentIndexes.includes(index) && "active"}
            image={product.image}
            name={product.name}
            key={`${product.name}-${index}`}
          />
        ))}
        <BsChevronLeft className={clsx(styles.arrow, styles["left-arrow"])} onClick={prevProduct} />
        <BsChevronRight
          className={clsx(styles.arrow, styles["right-arrow"])}
          onClick={nextProduct}
        />
      </div>
    </div>
  );
};

export default FeaturedProductsSlider;
