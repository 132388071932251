import { Helmet, HelmetProvider } from "react-helmet-async";

const SeoComponent = props => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>{props.title}</title>
        <meta name="description" content={props.description} />
      </Helmet>
    </HelmetProvider>
  );
};

export default SeoComponent;
