import React, { useEffect, useState } from "react";
import { IoIosArrowDropup } from "react-icons/io";

import styles from "./BackToTop.module.scss";
import clsx from "clsx";

const BackToTop = () => {
  const [isDisplayed, setDisplayedSetting] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const toggleVisible = () => {
      const scrolled = document.documentElement.scrollTop;
      if (scrolled > 300) {
        setDisplayedSetting(true);
      } else {
        setDisplayedSetting(false);
      }
    };

    window.addEventListener("scroll", toggleVisible);

    return () => {
      window.removeEventListener("scroll", toggleVisible);
    };
  }, []);

  return (
    <div
      className={clsx(styles["back-to-top"], isDisplayed && styles.display)}
      onClick={scrollToTop}
    >
      <div className={styles.background} />
      <IoIosArrowDropup />
    </div>
  );
};

export default BackToTop;
