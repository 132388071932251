import React, { useEffect, useState } from "react";
import styles from "./SearchBar.module.scss";
import searchIcon from "../../assets/icons/search_bar.svg";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import routes from "../../app/routes/routes";

const SearchBar = () => {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState(localStorage.getItem("inputValue"));
  const navigate = useNavigate();
  const location = useLocation();

  const handleSearchChange = event => {
    setSearchQuery(event.target.value);
    localStorage.setItem("inputValue", event.target.value);
  };

  const handleSubmit = event => {
    event.preventDefault();
    if (searchQuery.length > 0) {
      navigate(routes.searchResults.replace(":searchQuery", searchQuery));
    }
  };

  useEffect(() => {
    if (location.pathname !== routes.searchResults.replace(":searchQuery", searchQuery)) {
      setSearchQuery("");
    }
  }, [location.pathname]);

  return (
    <div className={clsx(styles["search-bar"], "d-flex", "align-items-center")}>
      <form className={styles["form-search"]} onSubmit={handleSubmit}>
        <button>
          <img src={searchIcon} alt={t("placeholders.search")} />
        </button>
        <input
          type="search"
          className={clsx(styles["input-search"], "p-3")}
          placeholder={t("placeholders.start_search")}
          onChange={handleSearchChange}
          value={searchQuery || ""}
        />
      </form>
    </div>
  );
};

export default SearchBar;
