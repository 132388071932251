import React from "react";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";

import ContactSectionComponent from "../../components/contactSectionComponent/ContactSectionComponent";
import Form from "../../components/form/Form";
import Zone from "../../components/zone/Zone";
import HeroImage from "../../components/heroImage/HeroImage";
import CategoriesMenu from "../../components/lateralMenu/CategoriesMenu";
import IconText from "../../components/iconText/IconText";
import { MdLocationPin } from "react-icons/md";

import styles from "./Contact.module.scss";
import CompanyBuilding from "../../assets/images/company_building.png";
import SeoComponent from "../../components/seoComponent/SeoComponent";

const iconsList = [
  {
    icon: MdLocationPin,
    texts: [
      {
        value: "contact.working_point_label",
        styleClass: "bold",
      },
      {
        value: "contact.working_point",
      },
      {
        value: "contact.working_point_address_label",
        styleClass: "bold",
      },
      {
        value: "contact.working_point_address",
      },
    ],
  },
];

const Contact = () => {
  const { t } = useTranslation();

  const showMenuOnMobile = isMobile && window.innerWidth < 767;

  return (
    <Zone styleClass={"flex-column"}>
      <SeoComponent
        title="Protectie și tratamente lemn împotriva apei | Sirtisa - Contact"
        description="Umezeala intervine deseori ca o afecțiune a sănătății lemnului. Protejarea lemnului împotriva apei poate fi efectuată ușor și facil cu produsele noastre!"
      />
      {showMenuOnMobile && <CategoriesMenu />}
      <div className={styles["upper-content"]}>
        <HeroImage
          styleClass="only-hero-image"
          heading={t("contact.hero_image_heading")}
          subheading={t("contact.hero_image_text")}
          image={CompanyBuilding}
        />
      </div>
      <div className={styles["lower-content"]}>
        <Form />
        <ContactSectionComponent />
      </div>
      <div className={styles["location-container"]}>
        <IconText icons={iconsList} styleClass="contact-page" />
      </div>
    </Zone>
  );
};

export default Contact;
