import React from "react";
import clsx from "clsx";
import styles from "./ProductCard.module.scss";

import RecommendedBanner from "../../assets/icons/product-recommended-banner.png";

const ProductCard = ({ image, name, styleClass }) => {
  return (
    <div className={clsx(styles["container"], styles[styleClass])}>
      <div className={styles["recommended-banner"]}>
        <img
          loading="lazy"
          className={styles["recommended-image"]}
          src={RecommendedBanner}
          alt={"Recommended banner"}
        />
      </div>
      <div className={styles["upper-content"]}>
        <img loading="lazy" className={styles.image} src={image} alt={`Product ${name}`} />
      </div>
      <div className={styles["lower-content"]}>
        <h3 className={styles.name}>{name}</h3>
      </div>
    </div>
  );
};

export default ProductCard;
