import React from "react";
import styles from "./ImageComponent.module.scss";
import clsx from "clsx";

const ImageComponent = ({ images, isRounded, roundedDestkop, displayColumn }) => {
  return (
    <div className={clsx(styles["container"], styles[displayColumn])}>
      {images.map((element, index) => (
        <div className={styles["image-box"]} key={index}>
          <img
            loading="lazy"
            className={clsx(
              styles["image-design"],
              isRounded && styles["rounded"],
              styles[roundedDestkop]
            )}
            src={element.image}
            alt={element.description}
          />
        </div>
      ))}
    </div>
  );
};

export default ImageComponent;
