import React, { Fragment } from "react";
import { useParams } from "react-router-dom";
import GridComponent from "../../components/gridComponent/GridComponent";
import CategoryCard from "../../components/categoryCard/CategoryCard";
import CategoriesMenu from "../../components/lateralMenu/CategoriesMenu";
import HeroImage from "../../components/heroImage/HeroImage";
import Zone from "../../components/zone/Zone";
import LoadingSpinner from "../../components/loadingSpinner/LoadingSpinner";
import Products from "../../components/products/Products";
import replaceUnderscore from "../../helpers/replaceUnderscore";

import { useProductsQuery, useSubcategoriesQuery } from "../../features/api/sirtisaApi";
import SeoComponent from "../../components/seoComponent/SeoComponent";

const Category = () => {
  const { categoryName } = useParams();
  const { data, error, isLoading, isSuccess } = useSubcategoriesQuery(categoryName);
  const hasNoSubcategories = data && !data.Subcategories.length ? true : undefined;
  const categoryProducts = useProductsQuery(
    {
      subcategoryName: categoryName,
      hasNoSubcategories,
    },
    { skip: hasNoSubcategories === undefined }
  );

  return (
    <Fragment>
      <SeoComponent
        title={`Soluții ignifugare lemn și tratamente lemn Bochemit | Sirtisa - ${replaceUnderscore(
          categoryName
        )}`}
        description="Dispunem de o gama largă de produse pentru a păstra lemnul sănătos. Verificați soluțiile de ignifugare, dar și vopsele de protecție pentru lemn!"
      />
      <Zone styleClass="max-height">
        <CategoriesMenu />
        <HeroImage heading={isSuccess && data.category_label} />
      </Zone>
      <Zone>
        {isLoading && <LoadingSpinner />}
        {error && <LoadingSpinner />}
        {isSuccess && data.Subcategories.length ? (
          <GridComponent>
            {data.Subcategories.map((subcategory, index) => (
              <CategoryCard
                name={subcategory.subcategory_name}
                label={subcategory.subcategory_label}
                image={null}
                id={subcategory.id}
                key={`${subcategory.subcategory_label}-${index}`}
                isSubCategory={true}
                makeButtonClickable={false}
              />
            ))}
          </GridComponent>
        ) : (
          <Zone>
            {categoryProducts.isSuccess && categoryProducts.data && (
              <Products products={categoryProducts.data} displayAlternative={true} />
            )}
          </Zone>
        )}
      </Zone>
    </Fragment>
  );
};

export default Category;
