/**
 * Returns a value from a given path inside an object
 *
 * @param {Object} object - object you want to retrieve information from
 * @param  {string} path - what properties to you want to go down to
 *
 * @example
 * safePick (category, "details", "categoryName", "text")
 * if category.details.categoryName.text exists, it will return that value,
 * otherwise it will return `null`
 *
 * @returns {*} - it can return anything: string, object, array, number, boolean, null
 */

const safePick = (object, ...path) => {
  if (!object || !path.length) {
    return null;
  }

  return path.reduce((accumulator, currentValue) => {
    if (!accumulator) {
      accumulator = null;
      return accumulator;
    }
    return accumulator[currentValue] || null;
  }, object);
};

export default safePick;
