import React, { Fragment } from "react";
import { isMobile } from "react-device-detect";

import GridComponent from "../../components/gridComponent/GridComponent";
import CategoryCard from "../../components/categoryCard/CategoryCard";
import HeroImage from "../../components/heroImage/HeroImage";
import Zone from "../../components/zone/Zone";
import LoadingSpinner from "../../components/loadingSpinner/LoadingSpinner";
import CategoriesMenu from "../../components/lateralMenu/CategoriesMenu";

import { useCategoriesQuery } from "../../features/api/sirtisaApi";
import { useTranslation } from "react-i18next";
import SeoComponent from "../../components/seoComponent/SeoComponent";

const Categories = () => {
  const { t } = useTranslation();
  const { data, error, isLoading, isSuccess } = useCategoriesQuery();

  const showMenuOnMobile = isMobile && window.innerWidth < 767;

  return (
    <Fragment>
      <SeoComponent
        title="Soluții ignifugare lemn și tratamente lemn Bochemit | Sirtisa - Categorii"
        description="Dispunem de o gama largă de produse pentru a păstra lemnul sănătos. Verificați soluțiile de ignifugare, dar și vopsele de protecție pentru lemn!"
      />
      <Zone styleClass="max-height">
        {showMenuOnMobile && <CategoriesMenu />}
        <HeroImage styleClass="only-hero-image" heading={t("general.category_title")} />
      </Zone>
      <Zone>
        {isLoading && <LoadingSpinner />}
        {error && <LoadingSpinner />}
        {isSuccess && (
          <GridComponent>
            {data.map((category, index) => (
              <CategoryCard
                name={category.category_name}
                label={category.category_label}
                image={null}
                id={category.id}
                key={`${category.category_label}-${index}`}
                makeButtonClickable={false}
              />
            ))}
          </GridComponent>
        )}
      </Zone>
    </Fragment>
  );
};

export default Categories;
