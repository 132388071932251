import React from "react";
import { useTranslation } from "react-i18next";
import Zone from "../../components/zone/Zone";
import SeoComponent from "../../components/seoComponent/SeoComponent";
import styles from "./CookiesPolicy.module.scss";

const CookiesPolicy = () => {
  const { t } = useTranslation();

  return (
    <Zone styleClass="orientation-column">
      <SeoComponent
        title="Sirtisa - Politica cookies"
        description="Politica de utilizare cookies si pagini web operate de Sirtisa. Consultă aici mai multe detalii!"
      />
      <header className={styles["header"]}>
        <h3>{t("pages.cookies_policy.headers.main_header")}</h3>
      </header>
      <section className={styles["section"]}>
        <h4>{t("pages.cookies_policy.headers.header_4.1")}</h4>
        <p>
          Acest website foloseste atât cookie-uri proprii, cât şi de la terţi, pentru a furniza
          vizitatorilor o experienţă mult mai bună de navigare şi servicii adaptate nevoilor şi
          interesului fiecăruia. În conformitate cu Directiva „e-Intimitate” a UE, în vigoare de la
          26 mai 2012 şi cu prevederile Legii nr. 506 din 17 noiembrie 2004 privind prelucrarea
          datelor cu caracter personal şi protecţia vieţii private în sectorul comunicaţiilor
          electronice, tuturor vizitatorilor website-ului li se cere consimţământul înaintea
          transmiterii de cookies în computerele acestora.
        </p>
        <h4>{t("pages.cookies_policy.headers.header_4.2")}</h4>
        <p>
          Un cookie este un fisier text de mici dimensiuni, format in general din identificatori,
          nume ale website-urilor litere si numere, caruia utilizatorul, cand navigheaza pe pagina
          web prin intermediul unui browser web (ex: Internet Explorer, Chrome, etc.), ii poate
          permite sau nu instalarea prin stocare pe computerul, terminalul mobil sau alte
          echipamente folosite de acesta. Fisierul cookie este “pasiv” (nu contine programe
          software, virusi sau spyware si nu poate accesa informatiile de pe hard disk-ul
          utilizatorului).
        </p>
        <h4>{t("pages.cookies_policy.headers.header_4.3")}</h4>
        <div className={styles["entire-paragraph"]}>
          Platforma
          <a className={styles["site-name"]} href="https://www.sirtisa.ro">
            &nbsp;www.sirtisa.ro&nbsp;
          </a>
          instalează module cookie doar pentru a permite îmbunătățirea funcțiilor oferite, astfel
          încât experiența ocazionată de vizitarea site-ului să devină mai plăcută. Domeniul
          <a className={styles["site-name"]} href="https://www.sirtisa.ro">
            &nbsp;www.sirtisa.ro&nbsp;
          </a>
          nu utilizează module cookie pentru a culege, a prelucra, a difuza sau a înregistra date cu
          caracter personal. „Cookie”-urile joacă un rol important în facilitarea accesului şi
          livrării multiplelor servicii de care utilizatorul se bucură pe Internet, cum ar fi:
          <ul>
            <li>
              Personalizarea anumitor setări precum:
              <ul>
                <li>limba în care este vizualizat un site;</li>
                <li>moneda în care se exprimă anumite preţuri sau tarife;</li>
                <li>
                  păstrarea opţiunilor pentru diverse produse (măsuri, alte detalii etc.) în coşul
                  de cumpărături (şi memorarea acestor opţiuni)
                </li>
                <li>generându-se astfel flexibilitatea „coşului de cumpărături”;</li>
                <li>
                  accesarea preferinţelor vechi prin accesarea butonului „Înainte” şi „Înapoi”.
                </li>
              </ul>
            </li>
            <li>
              Cookie-urile oferă deţinatorilor de site-uri feedback asupra modului cum sunt
              utilizate site-urile lor de către utilizatori, astfel încât să le poată face şi mai
              eficiente şi mai accesibile pentru utilizatori;
            </li>
            <li>
              Permit aplicaţiilor multimedia sau de alt tip de pe alte site-uri să fie incluse
              într-un anumit site pentru a crea o experienţă de navigare mai valoroasă, mai utilă şi
              mai placută;
            </li>
            <li>Îmbunătăţesc eficienţa publicităţii online.</li>
          </ul>
        </div>
        <h4>{t("pages.cookies_policy.headers.header_4.4")}</h4>
        <p>
          Un cookie conţine informaţii care face legătura între un web-browser (utilizator) şi un
          web-server anume (website-ul). Dacă un browser accesează acel web-server din nou, acesta
          poate citi informaţia deja stocată şi reacţiona în consecinţă. Cookie-urile asigură
          utilizatorilor o experienţă plăcută de navigare şi susţin eforturile multor website-uri
          pentru a oferi servicii confortabile utilizatorilor.
        </p>
        <h4>{t("pages.cookies_policy.headers.header_4.5")}</h4>
        <p>
          Durata de viaţă a unui cookie poate varia semnificativ, depinzând de scopul pentru care
          este plasat. Unele cookie-uri sunt folosite exclusiv pentru o singură sesiune (session
          cookies) şi nu mai sunt reţinute, odată ce utilizatorul a părăsit website-ul, în timp ce
          alte cookie-uri sunt reţinute şi refolosite de fiecare dată când utilizatorul revine pe
          acel website (‘cookie-uri persistente’). Cu toate acestea, cookie-urile pot fi şterse de
          un utilizator în orice moment prin intermediul setărilor browserului.
        </p>
        <h4>{t("pages.cookies_policy.headers.header_4.6")}</h4>
        <div className={styles["entire-paragraph"]}>
          Cookie-ul stochează informaţii importante, care îmbunătăţesc experienţa de navigare pe
          Internet, de exemplu:
          <ul>
            <li>setările limbii în care se doreşte accesarea unui site;</li>
            <li>păstrarea unui utilizator logat în contul de webmail;</li>
            <li>securitatea online banking;</li>
            <li>păstrarea produselor în coşul de cumpărături.</li>
          </ul>
        </div>
        <h4>{t("pages.cookies_policy.headers.header_4.7")}</h4>
        <div className={styles["entire-paragraph"]}>
          Cookie-urile reprezintă punctul central al funcţionării eficiente a Internetului, ajutând
          la generarea unei experienţe de navigare prietenoase şi adaptată preferinţelor şi
          intereselor fiecărui utilizator. Refuzarea sau dezactivarea cookie-urilor poate face unele
          website-uri imposibil de folosit. Refuzarea sau dezactivarea cookie-urilor nu înseamna că
          utilizatorul nu va mai primi publicitate online, ci doar că aceasta nu va mai putea ţine
          cont de preferinţele şi interesele utilizatorului, evidenţiate prin comportamentul de
          navigare. Exemple de întrebuinţări importante ale cookie-urilor (care nu necesită
          autentificarea unui utilizator prin intermediul unui cont):
          <ul>
            <li>
              conţinut şi servicii adaptate preferinţelor utilizatorului – categorii de ştiri,
              vreme, sport, hărţi, servicii publice şi guvernamentale, website-uri distractive şi
              servicii de turism;
            </li>
            <li>
              oferte adaptate pe interesele utilizatorilor – reţinerea parolelor, preferinţelor de
              limbă (ex. afişarea rezultatelor căutărilor în limba română) reţinerea filtrelor de
              protecţie a copiilor privind conţinutul pe Internet (opţiuni family mode, funcţii de
              safe search);
            </li>
            <li>
              măsurarea, optimizarea şi caracteristicile de analytics – cum ar fi: confirmarea unui
              anumit nivel de trafic pe un website, ce tip de conţinut este vizualizat şi modul cum
              un utilizator ajunge pe un website (ex. prin motoare de căutare, direct, din alte
              website-uri etc.). Deţinătorii website-urilor derulează aceste analize pentru a
              perfecţiona website-urile, în beneficiul utilizatorilor.
            </li>
          </ul>
        </div>
        <h4>{t("pages.cookies_policy.headers.header_4.8")}</h4>
        <div className={styles["entire-paragraph"]}>
          <p>
            Cookie-urile pot fi, totuşi, folosite pentru scopuri negative. Deoarece stochează
            informaţii despre preferinţele şi istoricul de navigare al utilizatorilor, atât pe un
            anume website cât şi pe alte website-uri, cookie-urile pot fi folosite ca o formă de
            Spyware. Multe produse anti-spyware sunt conştiente de acest fapt şi, în mod constant,
            marchează cookie-urile pentru a fi şterse, în cadrul procedurilor de ştergere/scanare
            anti-virus/anti-spyware.
          </p>
          <p>
            În general, browserele au integrate reglaje de confidenţialitate, care furnizează
            diferite nivele de acceptare a cookie-urilor, perioada de valabilitate şi ştergere
            automată după ce utilizatorul a vizitat un anumit site.
          </p>
          <p>
            Alte atacuri bazate pe cookie implică reglaje greşite ale cookie-urilor pe webservere.
            Dacă un website nu solicită browserului să folosească doar canale criptate, atacatorii
            pot folosi aceasta vulnerabilitate pentru a păcăli browserele în a trimite informaţii
            prin intermediul canalelor nesecurizate. Atacatorii utilizează apoi informaţiile cu
            scopul de a accesa neautorizat anumite site-uri.
          </p>
          <p>
            Este foarte important ca utilizatorii să fie atenţi în alegerea metodei celei mai
            potrivite de protecţie a informaţiilor personale.
          </p>
        </div>
        <h4>{t("pages.cookies_policy.headers.header_4.9")}</h4>
        <div className={styles["entire-paragraph"]}>
          <p>
            Datorită flexibilităţii lor şi a faptului că majoritatea dintre cele mai vizitate
            website-uri, inclusiv cele mai mari, folosesc cookie-uri, acestea sunt aproape
            inevitabile. Dezactivarea cookie-urilor nu va permite accesul utilizatorului pe
            site-urile cele mai răspândite şi utilizate, printre care: Facebook, Twitter, YouTube,
            Gmail, Yahoo şi altele.
          </p>
          <p>
            Iată câteva sfaturi care vă pot asigura că navigaţi fără grijă, însă cu ajutorul
            cookie-urilor:
          </p>
          <ul>
            <li>
              particularizaţi-vă reglajele browserului, în ceea ce priveşte cookie-urile, pentru a
              reflecta un nivel confortabil pentru dumneavoastră al securităţii utilizării
              cookie-urilor;
            </li>
            <li>
              dacă nu vă deranjează cookie-urile şi sunteţi singura persoană care utilizează
              computerul, puteţi regla termene lungi de expirare pentru stocarea istoricului de
              navigare şi al datelor personale de acces;
            </li>
            <li>
              dacă împărţiţi accesul la calculator, puteţi lua în considerare reglarea browserului
              pentru a şterge datele individuale de navigare de fiecare dată când închideţi
              browserul. Aceasta este o variantă de a accesa website-urile care plasează cookie-uri
              şi de a şterge orice informaţie de vizitare, la închiderea sesiunii de navigare;
            </li>
            <li>
              instalaţi-vă şi actualizaţi-vă constant aplicaţii antispyware. Multe dintre
              aplicaţiile de detectare şi prevenire a spyware-ului includ detectarea atacurilor pe
              site-uri. Astfel, împiedică browserul de a accesa website-uri care ar putea să
              exploateze vulnerabilităţile browserului sau să descarce software periculos;
            </li>
            <li>
              asiguraţi-vă că aveţi browserul mereu actualizat. Multe dintre atacurile bazate pe
              cookies se realizează exploatând punctele slabe ale versiunilor vechi ale browserelor.
            </li>
          </ul>
          <p>
            Cookie-urile sunt pretutindeni şi nu pot fi evitate, dacă doriţi să vă bucuraţi de acces
            pe cele mai bune şi cele mai mari site-uri de pe Internet, locale sau internaţionale. Cu
            o înţelegere clară a modului lor de operare şi a beneficiilor pe care le aduc, puteţi
            lua măsurile necesare de securitate, astel încât să puteţi naviga cu încredere pe
            Internet.
          </p>
        </div>
        <h4>{t("pages.cookies_policy.headers.header_4.10")}</h4>
        <div className={styles["entire-paragraph"]}>
          <a
            className={styles["site-name"]}
            href="https://support.microsoft.com/ro-ro/windows/%C8%99tergerea-%C8%99i-gestionarea-modulelor-cookie-168dab11-0753-043d-7c16-ede5947fc64d"
          >
            &nbsp;Setări cookies în Internet Explorer&nbsp;
            <br />
          </a>
          <a
            className={styles["site-name"]}
            href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?redirectlocale=en-US&redirectslug=Cookies"
          >
            &nbsp;Setări cookies în Mozilla Firefox &nbsp;
            <br />
          </a>
          <a
            className={styles["site-name"]}
            href="https://support.google.com/chrome/answer/95647?hl=en"
          >
            &nbsp;Setări cookies în Google Chrome&nbsp;
            <br />
          </a>
          <a
            className={styles["site-name"]}
            href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac"
          >
            &nbsp;Setări cookies în Safari&nbsp;
            <br />
          </a>
          <a className={styles["site-name"]} href="https://allaboutcookies.org/">
            &nbsp;Totul despre cookies&nbsp;
            <br />
          </a>
        </div>
      </section>
    </Zone>
  );
};

export default CookiesPolicy;
