const routes = {
  root: "/",
  aboutUs: "/despre-noi",
  categories: {
    root: "/categorii",
    id: "/categorie/:categoryName",
  },
  subcategories: {
    id: "/subcategorie/:subcategoryName",
  },
  contact: "/contact",
  termsAndConditions: "/termeni-si-conditii",
  cookies: "/politica-cookies",
  gdpr: "/politica-gdpr",
  searchResults: "/rezultatele-cautarii/:searchQuery",
  notFound: "*",
};

export default routes;
