import routes from "./routes";

export const goBack = navigate => {
  navigate(-1);
};

export const reloadPage = navigate => {
  navigate(0);
};

export const navigateToCategory = (navigate, categoryName) => {
  navigate(routes.categories.id.replace(":categoryName", categoryName));
};

export const navigateToSubcategory = (navigate, subcategoryName) => {
  navigate(routes.subcategories.id.replace(":subcategoryName", subcategoryName));
};
