import React from "react";
import clsx from "clsx";

import styles from "./HeroImage.module.scss";
import imageDefault from "../../assets/images/categories_cover.jpg";

const HeroImage = ({ image, heading, subheading, altText, styleClass }) => {
  return (
    <div className={clsx(styles["hero-image"], styles[styleClass])}>
      <div className={clsx(styles["image-container"], styles[styleClass])}>
        <img src={image || imageDefault} alt={altText} />
      </div>
      <div className={styles["text-container"]}>
        <h1>{heading || ""}</h1>
        <p>{subheading || ""}</p>
      </div>
    </div>
  );
};

export default HeroImage;
