import React from "react";
import Zone from "../../components/zone/Zone";
import CategoriesMenu from "../../components/lateralMenu/CategoriesMenu";
import HeroImage from "../../components/heroImage/HeroImage";
import ImageComponent from "../../components/imageComponent/ImageComponent";
import styles from "./About.module.scss";
import Brush from "../../assets/images/brush.png";
import Doorknob from "../../assets/images/doorknob.png";
import Colors from "../../assets/images/colors.png";
import { useTranslation } from "react-i18next";
import AboutUs from "../../assets/images/about_us.png";
import SeoComponent from "../../components/seoComponent/SeoComponent";

const About = () => {
  const { t } = useTranslation();

  const imagesList = [
    {
      image: Brush,
      description: t("components.images.image_description3"),
    },
    {
      image: Doorknob,
      description: t("components.images.image_description4"),
    },
    {
      image: Colors,
      description: t("components.images.image_description5"),
    },
  ];

  const renderBodyText = () => (
    <div className={styles["about-us-content"]}>
      <SeoComponent
        title="Tratamente lemn anticarii și antimucegai | Sirtisa - Despre noi"
        description="Factorii externi n-ar trebui să strice sănătatea lemnului. Produsele și tratamentele noastre anticarii și antimucegai vor oferi protecția necesară acestuia!"
      />
      <h2 className="hidden-mobile">Despre noi</h2>
      <p>
        Calitate, valoare, dedicare către clienți, angajament pentru excelență și originalitate,
        îndeplinirea obligațiilor către salariați, furnizori, clienți, parteneri de afaceri și către
        comunitate. Acestea sunt principiile pe care se bazează SIRTISA CONSULTING.
      </p>
      <br />
      <p>
        S.C. SIRTISA CONSULTING S.R.L. își desfășoară activitatea începând din anul 1996, având ca
        specializare furnizarea de produse și servicii pentru industria lemnului.
      </p>
      <p>
        Cu o experiență de peste 25 de ani în domeniu, punem la dispoziția dumneavoastră
        profesionalism, seriozitate și promptitudine, satisfăcând solicitările tuturor clienților
        noștri de la producători de mobilă, designeri, arhitecți, producători de case din lemn și
        binale până la consumatori finali - persoane fizice.
      </p>
      <p>
        Prioritățile noastre sunt reflectate atât prin calitatea serviciilor și produselor oferite
        cât și prin dedicarea și profesionalismul angajaților noștri. Scopul nostru este de a
        îmbunătăți continuu activitatea producătorilor din industria lemnului, oferindu-le
        consultanță tehnică de specialitate și produse de cea mai înaltă calitate de la producători
        de renume.
      </p>
      <p>
        Personalul nostru calificat (ingineri chimiști, ingineri din industria lemnului s.a.) este
        la dispoziția clienților și partnerilor noștri de afaceri pentru a le oferi consultanță și
        modalități optime de rezolvare a diverselor probleme ce se pot ivi în oricare din etapele de
        procesare, prelucare și punere în operă a lemnului.
      </p>
      <p>
        Din 2001 suntem dealer autorizat HÄFELE, oferind peste 10000 de articole de feronerie și
        accesorii de mobilier: de la îmbinări la elemente decorative, de la închideri la soluții de
        iluminat, de la clasic la modern.
      </p>
      <br />
      <p>
        Începând cu 2003 am dezvoltat departamentul destinat finisajelor profesionale pentru lemn.
        De la început am selectat produse de înaltă calitate de la producători renumiți precum
        SAYERLACK sau ICA. Comportamentul în timp și aspectul plăcut au confirmat alegerile făcute.
        Oferim finisaje, efecte speciale și produse cu destinație specifică: decking, saune,
        finisaje retro sau futuriste. Vioiciunea culorilor și tușeul plăcut asigură o ambianță
        încântătoare familiei sau colectivului.
      </p>
      <br />
      <p>
        Din 2006 suntem partener de afaceri al firmei BOCHEMIE CEHIA - producător de produse biocide
        destinate tratării lemnului, proces de mare importanță în conservarea și creșterea
        rezistenței în timp a materialului lemnos.
      </p>
      <p>
        In 2018 am completat gama de feronerie pentru mobilier cu produse ale unuia dintre cei mai
        mari producatori din Polonia, GTV.
      </p>
      <p>
        Suntem importator al chiturilor pentru lemn Emmebi-Italia (gamă diversificată de culori,
        compatibilitate foarte bună cu produsele de finisaj folosite ulterior, nu cade în pori).
      </p>
      <p>
        Utilizăm în ascuțitoria proprie și vindem discuri diamantate de cea mai bună calitate,
        utilizate la ascuțirea sculelor destinate prelucrării lemnului, marca INTER-DIAMENT.
      </p>
    </div>
  );

  return (
    <div className={styles["about-main-container"]}>
      <Zone>
        <div className={styles["categories-menu-container"]}>
          <CategoriesMenu />
        </div>
        <HeroImage image={AboutUs} heading={" "} subheading={" "} styleClass={"no-image-filter"} />
      </Zone>
      <Zone>
        <div className={styles["image-and-text"]}>
          <h2 className="text-center">Despre noi</h2>
          <ImageComponent
            images={imagesList}
            isRounded={true}
            displayColumn={"display-column"}
            roundedDestkop={"rounded-desktop"}
          />
          {renderBodyText()}
        </div>
      </Zone>
    </div>
  );
};

export default About;
