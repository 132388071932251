import React from "react";
import styles from "./Products.module.scss";
import clsx from "clsx";
import { useImagesQuery } from "../../features/api/sirtisaApi";

import Slider from "../slider/Slider";
import FlipCard from "../flipCard/FlipCard";
import NoProducts from "../noProducts/NoProducts";

const ATTRIBUTES_KEY_MAP = [
  {
    label: "label_attribute_1",
    value: "value_attribute_1",
  },
  {
    label: "label_attribute_2",
    value: "value_attribute_2",
  },
  {
    label: "label_attribute_3",
    value: "value_attribute_3",
  },
  {
    label: "label_attribute_4",
    value: "value_attribute_4",
  },
  {
    label: "label_attribute_5",
    value: "value_attribute_5",
  },
];

const Product = ({
  productId,
  heading,
  description,
  attributes,
  shouldDisplayAlternative,
  searchPage,
}) => {
  const { data, isSuccess } = useImagesQuery({ id: productId, type: "products" });

  const hasAttributes = attributes.length;
  const filteredAttributes =
    hasAttributes &&
    attributes.reduce((accumulator, currentValue, currentIndex) => {
      if (currentValue[ATTRIBUTES_KEY_MAP[currentIndex].label]) {
        accumulator.push(
          <li
            className={styles["attribute-container"]}
            key={`${currentValue[`label_attribute_${currentIndex + 1}`]}-${currentIndex}`}
          >
            <span className={clsx(styles.label, styles.bold)}>
              {currentValue[ATTRIBUTES_KEY_MAP[currentIndex].label]}
            </span>
            <span className={styles.value}>
              {currentValue[ATTRIBUTES_KEY_MAP[currentIndex].value]}
            </span>
          </li>
        );
        return accumulator;
      }
      return accumulator;
    }, []);

  return (
    <div
      className={clsx(
        styles["product-container"],
        shouldDisplayAlternative && styles.alternative,
        searchPage && styles["search-page"]
      )}
    >
      <div className={styles["top-content"]}>
        <Slider styleClass="product-slider" data={isSuccess && data} />
      </div>
      <div className={styles["bottom-content"]}>
        <h3 className={clsx(styles.heading, styles.bold)}>
          {heading
            .trim()
            .split(" ")
            .map(s => `${s[0].toUpperCase()}${s.slice(1).toLowerCase()}`)
            .join(" ")}
        </h3>
        <p className={clsx(styles.description)}>{description}</p>
        {!!filteredAttributes.length && (
          <ul className={styles["product-attributes"]}>{filteredAttributes}</ul>
        )}
      </div>
    </div>
  );
};

const Products = ({ products, flipCardData, displayAlternative = false, searchPage = false }) => {
  let inputProducts;

  if (!products.Products) {
    return null;
  } else {
    inputProducts = products.Products;
  }

  return (
    <div className={styles["products-container"]}>
      {flipCardData && <FlipCard data={flipCardData} />}
      {inputProducts.length > 0 ? (
        <div
          className={clsx(
            styles["products-list"],
            inputProducts.length === 1 && styles["only-1"],
            inputProducts.length <= 2 && styles["only-2"]
          )}
        >
          {products &&
            products.Products.map((product, index) => {
              return (
                <Product
                  productId={product.id}
                  heading={product.product_name}
                  description={product.product_description}
                  attributes={product.attributes}
                  shouldDisplayAlternative={displayAlternative}
                  searchPage={searchPage}
                  key={`${product.heading}-${index}`}
                />
              );
            })}
        </div>
      ) : (
        <NoProducts />
      )}
    </div>
  );
};

export default Products;
