import { configureStore } from "@reduxjs/toolkit";
import menuButtonReducer from "../features/navigationMenuButton/menuButtonSlice";
import { sirtisaApi } from "../features/api/sirtisaApi";

export const store = configureStore({
  reducer: {
    [sirtisaApi.reducerPath]: sirtisaApi.reducer,
    menuButton: menuButtonReducer,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(sirtisaApi.middleware),
});
