import React from "react";
import { Fragment } from "react";
import { useParams } from "react-router-dom";
import Zone from "../../components/zone/Zone";
import CategoriesMenu from "../../components/lateralMenu/CategoriesMenu";
import HeroImage from "../../components/heroImage/HeroImage";
import Products from "../../components/products/Products";
import LoadingSpinner from "../../components/loadingSpinner/LoadingSpinner";
import safePick from "../../helpers/safePick";
import replaceUnderscore from "../../helpers/replaceUnderscore";

import { useProductsQuery, useSubcategoryQuery } from "../../features/api/sirtisaApi";
import SeoComponent from "../../components/seoComponent/SeoComponent";

const Subcategory = () => {
  const { subcategoryName } = useParams();
  const subcategoryQuery = useSubcategoryQuery(subcategoryName);
  const { data, error, isLoading, isSuccess } = useProductsQuery({
    subcategoryName,
  });

  return (
    <Fragment>
      <SeoComponent
        title={`Soluții ignifugare lemn și tratamente lemn Bochemit | Sirtisa - ${replaceUnderscore(
          subcategoryName
        )}`}
        description="Dispunem de o gama largă de produse pentru a păstra lemnul sănătos. Verificați soluțiile de ignifugare, dar și vopsele de protecție pentru lemn!"
      />
      <Zone styleClass="max-height">
        <CategoriesMenu />
        <HeroImage
          heading={isSuccess && data.subcategory_label}
          image={isSuccess && data.subcategory_image}
          altText={isSuccess && data.subcategory_name}
        />
      </Zone>
      {isLoading && <LoadingSpinner />}
      {error && <LoadingSpinner />}
      {isSuccess && (
        <Zone>
          <Products
            products={data}
            flipCardData={safePick(subcategoryQuery, "data", "flipCardData")}
            displayAlternative={true}
          />
        </Zone>
      )}
    </Fragment>
  );
};

export default Subcategory;
