import React from "react";
import styles from "./FeaturedCategories.module.scss";
import { useTranslation } from "react-i18next";

import CategoryCard from "../categoryCard/CategoryCard";

const FeaturedCategories = ({ makeButtonClickable = false }) => {
  const { t } = useTranslation();

  const mockedCategories = [
    {
      name: "materiale_tratare_protectie_lemn",
      text: t("categories.wood_protection"),
      image: null,
    },
    {
      name: "feronerie_accesorii_mobilier",
      text: t("categories.furniture_accessories"),
      image: null,
    },
    {
      name: "produse_finisare",
      text: t("categories.finishing_products"),
      image: null,
    },
  ];

  return (
    <div className={styles["featured-categories"]}>
      <h2 className={styles.heading}>{t("components.featured_categories.heading")}</h2>
      <div className={styles.categories}>
        {mockedCategories.map((category, index) => {
          return (
            <CategoryCard
              name={category.name}
              label={category.text}
              image={category.image}
              isSubCategory={false}
              makeButtonClickable={makeButtonClickable}
              key={`${category.text}-${index}`}
            />
          );
        })}
      </div>
    </div>
  );
};

export default FeaturedCategories;
