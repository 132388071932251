import React, { useState, useRef, useEffect } from "react";
import clsx from "clsx";

import styles from "./Slider.module.scss";

import Slide1 from "../../assets/images/slider/slide_1.jpg";
import Slide2 from "../../assets/images/slider/slide_2.jpg";
import Slide3 from "../../assets/images/slider/slide_3.jpg";

const homepageDataSlider = [
  {
    src: Slide1,
    alt: "Panze circulare placate CMS sau monometal - Sirtisa Consulting",
  },
  {
    src: Slide2,
    alt: "Feronerie si accesorii pentru mobilier - Sirtisa Consulting",
  },
  {
    src: Slide3,
    alt: "Materiale pentru interior/exterior - Sirtisa Consulting",
  },
];

/**
 * Slider component
 *
 * @component
 */
const Slider = props => {
  const [slideIndex, setSlideIndex] = useState(1);
  const timeoutRef = useRef(null);
  const sliderTimer = 5000;

  const resetTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () => setSlideIndex(prevIndex => (prevIndex === dataSlider.length ? 1 : prevIndex + 1)),
      sliderTimer
    );

    return () => {
      resetTimeout();
    };
  }, [slideIndex]);

  const moveDot = index => {
    setSlideIndex(index);
  };

  let dataSlider = [];

  if (props.data) {
    dataSlider = props.data;
  } else if (props.isHomepage) {
    dataSlider = homepageDataSlider;
  }

  const hasMultipleSlides = dataSlider && dataSlider.length > 1;

  return (
    <div className={clsx(styles["container-slider"], styles[props.styleClass])}>
      {dataSlider.map((obj, index) => {
        return (
          <div
            className={clsx(
              styles.slide,
              hasMultipleSlides
                ? slideIndex === index + 1 && styles["active-anim"]
                : styles["active-anim"]
            )}
            key={`slide-${index}`}
          >
            <img loading="lazy" src={obj.image_name ?? obj.src} alt={obj.alt} />
          </div>
        );
      })}

      {hasMultipleSlides && (
        <div className={styles["container-dots"]}>
          {Array.from({ length: dataSlider.length }).map((item, index) => (
            <div
              className={clsx(styles.dot, slideIndex === index + 1 && styles.active)}
              onClick={() => moveDot(index + 1)}
              key={index}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default Slider;
