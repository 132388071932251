import React from "react";
import clsx from "clsx";

import styles from "./Zone.module.scss";

const Zone = props => {
  return <div className={clsx(styles.zone, styles[props.styleClass])}>{props.children}</div>;
};

export default Zone;
