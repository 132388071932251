import React from "react";
import styles from "./Select.module.scss";

const Select = ({ options = [], name, value, onChange, styleClass }) => {
  return (
    <select className={styles[styleClass]} name={name} value={value} onChange={onChange}>
      {options.map((optionElement, index) => (
        <option key={`${optionElement}-${index}`}>{optionElement}</option>
      ))}
    </select>
  );
};

export default Select;
