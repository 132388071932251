import React, { useState } from "react";
import styles from "./CategoriesMenu.module.scss";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { navigateToCategory } from "../../app/routes/routingHelper";
import SubcategoriesMenu from "./SubcategoriesMenu";
import LoadingSpinner from "../loadingSpinner/LoadingSpinner";

import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useCategoriesAndSubcategoriesQuery } from "../../features/api/sirtisaApi";

const CategoriesMenu = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isSelectedId, setIsSelectedId] = useState(undefined);
  const [isOpenSubmenu, setIsOpenSubmenu] = useState(false);
  const isOpenMenuButton = useSelector(state => state.menuButton.isOpen);

  const { data, error, isLoading, isSuccess } = useCategoriesAndSubcategoriesQuery();

  const handleOpenSubmenu = (id, category_name, subcategoriesLength) => {
    setIsSelectedId(id);
    setIsOpenSubmenu(true);
    const innerWidth = window.innerWidth;
    if (innerWidth > 768 || !subcategoriesLength) {
      navigateToCategory(navigate, category_name);
    }
  };

  const closeSubmenu = () => {
    setIsSelectedId(undefined);
    setIsOpenSubmenu(false);
  };

  window.innerWidth < 768 && isOpenMenuButton
    ? (document.body.style.overflow = "hidden")
    : (document.body.style.overflow = "auto");

  const renderCategoriesMenuHeader = () => (
    <div className={clsx(styles["category-header"], "p-3")}>
      <p className="my-2">{t("navigation.header.categories")}</p>
    </div>
  );

  const renderCategoriesOptions = () => (
    <div
      className={clsx(
        styles["category-body"],
        isOpenSubmenu ? styles["overflow-y-unset"] : styles["overflow-y"]
      )}
    >
      <ul className={clsx(styles["category-ul"], "p-0")}>
        {data.map((menuItem, index) => (
          <li
            key={`${menuItem.id}-${index}`}
            className="d-flex justify-content-between"
            onClick={() =>
              handleOpenSubmenu(menuItem.id, menuItem.category_name, menuItem.Subcategories.length)
            }
          >
            {menuItem.category_label}
            <SubcategoriesMenu
              title={menuItem.category_label}
              items={menuItem.Subcategories}
              isOpen={menuItem.id === isSelectedId}
              closeSubmenu={closeSubmenu}
            />
          </li>
        ))}
      </ul>
    </div>
  );

  return (
    <div
      className={clsx(
        styles["categories-menu-parent"],
        !isOpenMenuButton && styles["transform-mobile"]
      )}
    >
      <div
        className={clsx(
          styles["categories-menu"],
          "d-flex",
          "flex-column",
          isLoading || (!isSuccess && styles.loading)
        )}
      >
        {renderCategoriesMenuHeader()}
        {isLoading && <LoadingSpinner />}
        {error && <LoadingSpinner />}
        {isSuccess && renderCategoriesOptions()}
      </div>
    </div>
  );
};

export default CategoriesMenu;
