import React from "react";
import ContactUs from "../../assets/images/contact-us.png";
import styles from "./NoProducts.module.scss";

const NoProducts = () => {
  return (
    <div className={styles[["no-products-container"]]}>
      <p>
        Pentru a obține o listă a produselor din această categorie împreună cu prețurile acestora,
        vă rugăm să ne contactați utilizând formularul de contact sau la unul din numerele de
        telefon de
        <strong>
          <a href="/contact"> aici</a>
        </strong>
        .
      </p>
      <img src={ContactUs} alt="Contactați-ne - Sirtisa.ro" />
    </div>
  );
};

export default NoProducts;
