import React, { Fragment } from "react";
import clsx from "clsx";
import styles from "./SubcategoriesMenu.module.scss";
import arrow from "../../assets/icons/arrow.svg";
import { useTranslation } from "react-i18next";
import { navigateToSubcategory } from "../../app/routes/routingHelper";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { openOrCloseMenu } from "../../features/navigationMenuButton/menuButtonSlice";

const SubcategoriesMenu = ({ isOpen, title, items, closeSubmenu }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleCloseSubmenu = event => {
    closeSubmenu();
    event.stopPropagation();
  };

  if (!items.length) {
    return null;
  }

  return (
    <Fragment>
      <img className="ml-4" src={arrow} alt={t("placeholders.arrow")} />

      <ul
        className={clsx(
          styles["subcategory-body"],
          "p-0",
          isOpen && styles["subcategory-body-active"]
        )}
      >
        <li
          className={clsx(styles["title"], "py-3", "px-1")}
          onClick={event => handleCloseSubmenu(event)}
        >
          <img
            className={clsx(styles["rotate-180"], "px-3")}
            src={arrow}
            alt={t("placeholders.arrow")}
          />
          {title}
        </li>
        {items.map((subcategoryItem, index) => (
          <li
            key={`${subcategoryItem.id}-${index}`}
            className="py-4 px-3"
            onClick={event => {
              event.stopPropagation();
              navigateToSubcategory(navigate, subcategoryItem.subcategory_name);
              window.innerWidth < 768 && dispatch(openOrCloseMenu(false));
            }}
          >
            {subcategoryItem.subcategory_label}
          </li>
        ))}
      </ul>
    </Fragment>
  );
};

export default SubcategoriesMenu;
