import React from "react";
import { useTranslation } from "react-i18next";
import Zone from "../../components/zone/Zone";
import SeoComponent from "../../components/seoComponent/SeoComponent";
import styles from "./GdprPolicy.module.scss";

const GdprPolicy = () => {
  const { t } = useTranslation();

  return (
    <Zone styleClass="orientation-column">
      <SeoComponent
        title="Sirtisa - Politica GDPR"
        description="Politica GDPR a pagini web operate de Sirtisa. Consultă aici mai multe detalii!"
      />
      <header className={styles["header"]}>
        <h3>{t("pages.gdpr_policy.headers.main_header")}</h3>
      </header>
      <section className={styles["section"]}>
        <p>
          <strong>{t("pages.gdpr_policy.company_legal_name")}</strong> garantează securitatea și
          confidențialitatea datelor găzduite și transmise prin sistemul sau informatic. Aceste
          informații vor putea fi folosite de către
          <strong>{t("pages.gdpr_policy.company_legal_name")}</strong> pentru a trimite
          utilizatorului confirmarea comenzilor, diverse oferte speciale, promoții, etc. numai în
          baza consimtamântului persoanei vizate.
        </p>
        <p>
          Furnizarea datelor personale către{" "}
          <strong>{t("pages.gdpr_policy.company_legal_name")}</strong> nu implică obligativitate din
          partea utilizatorilor, iar aceștia pot refuză furnizarea acestor date în orice
          circumstanțe și pot solicită în mod gratuit ștergerea acestora din baza de date{" "}
          <strong>{t("pages.gdpr_policy.company_legal_name")}</strong>, proprietar al platformei
          online. Acesta nu intervine direct sau indirect asupra bazelor de date unde sunt stocate
          informațiile despre clienți.
        </p>
        <p>
          Pentru a face posibilă facturarea, expedierea și livrarea comenzilor plasate, trebuie că
          utilizatorul să fie de acord că{" "}
          <strong>{t("pages.gdpr_policy.company_legal_name")}</strong> să colecteze și să prelucreze
          datele înscrise, conform cerințelor Legii nr. 679/2016 (GDPR). Conform cerințelor Legii
          nr. 679/2016 (GDPR) pentru protecția persoanelor cu privire la prelucrarea datelor cu
          caracter personal și protecția vieții private în sectorul comunicațiilor electronice,{" "}
          <strong>{t("pages.gdpr_policy.company_legal_name")}</strong> are obligația de a administra
          în condiții de siguranță și numai pentru scopurile specificate, datele personale
          furnizate. În acest sens, <strong>{t("pages.gdpr_policy.company_legal_name")}</strong> a
          elaborat o serie de măsuri tehnice și organizatorice pentru prevenirea riscurilor ce pot
          apărea în cadrul prelucrării datelor cu caracter personal. Prelucrarea datelor cu caracter
          personal în interiorul organizației este condiționată de o serie de măsuri tehnice și
          organizatorice în vederea securizarii acestora. Aceste măsuri au rolul de a proteja
          informațiile la nivelul organizației împotriva incidentelor de securitate.
        </p>
        <h3>{t("pages.gdpr_policy.headers.header_4.1")}</h3>
        <ol>
          <li>
            <h4>{t("pages.gdpr_policy.headers.header_4.2")}</h4>
            <ul>
              <li>
                Certificatul SSL are rolul de a securiza schimbul de informații prin Internet.
                Acesta criptează informațiile, înaintea că acestea să circule prin Internet.
                Informațiile criptate nu pot fi decriptate decât de serverul căruia ii sunt
                adresate. Acest lucru garantează că informațiile trimise către un website/platforma
                online nu vor fi furate, interceptate, prelucrate. Informațiile despre carduri
                bancare, parole și în general orice informație care se dorește a rămâne privată este
                securizată de acest certificat;
              </li>
              <li>
                Certificatul SSL al platformei online{" "}
                <strong>{t("pages.gdpr_policy.company_legal_name")}</strong> este folosit și pentru
                securizarea corespondenței pe e-mail, în așa manieră încât, datele personale ale
                clienților să circule într-un mediu securizat și reglementat de o serie de măsuri de
                securitate care asigura confidențialitatea informațiilor;
              </li>
              <li>
                Back-UP automat setat la un interval de timp pentru garantarea informațiilor și
                pentru că toți clienții să fie siguri că informațiile și preferințele furnizate de
                ei nu dispar și nu sunt distruse, pierdute sau incorecte în cazul unei erori a
                serverului;
              </li>
              <li>
                Filtre anti-spam și antivirus care previn infiltrarea conținutul malițios sau a unor
                viruși care pot prelucra datele în mod neautorizat sau care le pot transmite către
                alte entități sau persoane care nu au obținut consimțământul persoanei vizate;
              </li>
              <li>
                Securizarea modulelor și a scripturilor care comunică în interiorul platfomei. Se
                verifică în mod constant funcționarea elementelor implicate în interacțiunea
                client-server, server-client;
              </li>
              <li>
                Verificarea și optimizarea modulelor în vederea menținerii lor up-to-date pentru
                prevenirea vulnerabilităților.Această măsură previne identificarea
                vulnerabilităților la nivel global în platfomele utilizate, vulnerabilități de tip
                0-day ce pot intercepta schimbul de date și implicit date cu caracter personal în
                interacțiunile clientului cu platforma sau ale responsabilului de proces cu clientul
                și platforma;
              </li>
              <li>
                Clasificarea tipurilor de acces de către responsabilul de proces – grupuri de
                administrare, posibilitatea de a adaugă sau șterge anumite drepturi pe un user cu
                acces deplin – personalizarea accesului în funcție de necesitate;
              </li>
              <li>
                Protejarea cu parolă a dispozitivului de pe care, responsabilul de proces efectuează
                prelucrările de date, pentru a preveni intervenția neautorizată;
              </li>
              <li>
                Firewall este program software și componentă hardware instalată în locația
                serverelor al firmei care oferă hostingul platformei online, este destinat
                protejării serverului și al echipamentelor de rețea, împotriva atacurilor
                informatice, încercării de pătrundere neautorizată, instalării de aplicații software
                malițioase ce pot pune în pericol datele cu caracter personal ale utilizatorilor
                platformei. Acesta blochează accesul persoanelor neautorizate la informațiile
                stocate pe echipamentul conectat la Internet;
              </li>
              <li>
                Accesul la sistemele de procesare a datelor în care sunt procesate datele personale
                este posibil doar după ce persoană autorizată a fost identificată și autentificată
                cu succes (ex. cu nume de utilizator și parolă sau card cu recunoaștere
                facială/PIN), cu utilizarea măsurilor de securitate cele mai performanțe. În cazul
                lipsei autorizației, accesul este refuzat;
              </li>
              <li>
                Toate încercările de accesare, atât cele reușite, cât și cele respinse, sunt
                înregistrate (ID de utilizator, computer, adresa IP utilizată) și arhivate într-un
                format conform regulilor de audit pentru 3 luni. În vederea detectării folosirii
                necorespunzătoare, serverul efectuează verificări repetate, aleatorii;
              </li>
              <li>Accesul este blocat după încercări de autentificare incorecte repetate;</li>
              <li>
                Verificarea constantă a vulnerabilităților platformei, care ar putea permite
                extragerea de informații și date cu caracter personal. Hostingul are măsuri și
                soluții de securitate care scanează recurent fișierele procesate și fluxul de date
                care circulă în interiorul platformei;
              </li>
              <li>
                Combaterea riscurilor unor breșe de securitate prin luarea măsurilor de precauție
                din punct de vedere tehnic și organizatoric prin securizarea platformei și
                actualizarea constantă cu versiuni stabile ale acesteia.
              </li>
            </ul>
          </li>
          <li>
            <h4>{t("pages.gdpr_policy.headers.header_4.3")}</h4>
            <ul>
              <li>
                Distrugerea documentelor care nu mai sunt necesare (notițe, facturi eronate, etc)
                utilizând un distrugător de documente la dispoziția responsabilului de proces;
              </li>
              <li>
                Eliminarea riscului generat de factorul uman prin interzicerea prelucrării de
                informații în afară platformei securizate cu excepția întocmirii notelor de
                transport în platforma firmei de curierat, care, este de asemenea, un mediu
                securizat;
              </li>
              <li>
                Adoptarea măsurilor de securitate fără a face diferențierea între tipurile de
                clienți (nou/ existent / potențial);
              </li>
              <li>
                Adoptarea unei politici interne de verificare a proceselor și al prelucrărilor în
                momentul punerii în livrare a produsului sau preluării informațiilor cu privire la o
                comandă sau posibilă ofertă;
              </li>
              <li>
                Evitarea diferențierii între clienți prin mecanisme ce pot profila în mod pozitiv
                sau negativ persoană vizată. Din acest motiv , nu solicităm date cu caracter
                personal orientarea sexuală, interesele sexuale, sexul , religia, apartenența la
                mișcări sau grupări, etc. Clienții sunt liberi să comande și să aleagă ceea ce își
                doresc. Prin această măsură, considerăm că respectăm integritatea persoanei și
                evităm orice urmă de analiză/profilare pe baza acestor criterii;
              </li>
              <li>
                Actualizarea politicii de confidențialitate{" "}
                <strong>{t("pages.gdpr_policy.company_legal_name")}</strong>;
              </li>
              <li>
                Instruirea responsabilului de proces cu privire la necesitatea notificării în cazul
                unui incident de securitate major;
              </li>
              <li>
                Instruirea responsabilului de proces cu privire la gestionarea situațiilor ce pot
                apărea în momentul prelucrării datelor în interiorul platformei (erori, erori de
                utilizare);
              </li>
              <li>
                Instruirea responsabilului de proces cu privire la utilizarea informațiilor pe care
                le prelucrează și conștientizarea asupra caracterului informațiilor personale;
              </li>
              <li>
                Interzicerea prelucrării datelor în afară platformei prin gestionarea comenzilor
                direct în interfață de utilizare a platformei, nefiind necesară prelucrarea datelor
                în alte medii nesecurizate și vulnerabile;
              </li>
              <li>Responsabilul de proces este instruit periodic cu privire la:</li>
              <ul>
                <li>
                  Principiile protecției datelor, inclusiv măsurile tehnice și organizatorice;
                </li>
                <li>
                  Cerință de a păstra secretul datelor și confidențialitatea cu privire la secretele
                  organizației și secretul comercial, inclusiv tranzacțiile realizate;
                </li>
                <li>
                  Utilizarea corectă, atentă a datelor, mediilor de date și a altor documente;
                </li>
                <li>Secretul telecomunicațiilor;</li>
                <li>
                  Alte obligații specifice privind confidențialitatea, acolo unde este necesar.
                </li>
              </ul>
            </ul>
          </li>
        </ol>
        <div className={styles["entire-paragraph"]}>
          Din punct de vedere al prelucrării, în cadrul{" "}
          <strong>{t("pages.gdpr_policy.company_legal_name")}</strong>, datele cu caracter personal
          sunt prelucrate doar pentru scopurile pentru care s-a obținut consimțământul persoanelor
          vizate, inclusiv pentru scopurile paralele și pentru încheierea unui contract sau livrarea
          unui produs către client, cerut de acesta.
          <br />
          Având în vedere că această organizație își desfășoară activitatea în cea mai mare parte în
          mediul online, prelucrarea datelor cu caracter personal ale clienților sunt transmise
          online prin intermediul aplicațiilor și al platformei pe care se solicită comenzi și
          cereri de oferta. Datele colectate sunt minimizate și sunt în legătură directă cu scopul
          pentru care s-a obținut consimțământ și sunt necesare pentru a contacta clientul în cazul
          unei cereri de oferta sau pentru a livra și pune la dispoziție produsul/serviciul comandat
          conform cerințelor sau returul acestuia.
          <br />
          Scopul prelucrării datelor cu caracter personal este furnizarea de produse și servicii
          prin intermediul platformei online cât și scopurile paralele ale acestei activități:
          returnarea produselor, procesarea informațiilor necesare livrării, îmbunătățirea
          experienței pentru utilizator prin reținerea anumitor setări sau preferințe, după
          obținerea consimtamântului acestuia, modificări de preț, caracteristici ale
          produselor/serviciilor, modificări ale stocului, promoții, facturare.
          <br />
          Categoriile de persoane vizate sunt: clienți actuali, potențiali clienți sau vizitatori ai
          paginii web. Modul în care persoanele vizate sunt informate asupra drepturilor lor este
          reprezentat de pagina dedicată „Prelucrarea datelor cu caracter personal”.
          <br />
          Exercitarea drepturilor prevăzute de legea 679 / 2016 (GDPR) revin în totalitate
          operatorului care are obligația legală și de a-și desemna o persoană responsabilă de
          procesarea datelor personale în interiorul organizației. Această persoană va elabora un
          set de măsuri tehnice și organizatorice pentru securizarea prelucrării datelor și are
          obligația de a informa operatorul privire la natură proceselor de prelucrare, tipuri de
          informații și modul în care, aceste procese se desfășoară în interiorul organizației.
          Operatorul are răspunderea și obligația de a se asigura că aceste măsuri sunt
          implementate, că nu există riscul unor breșe de securitate sau a unor scurgeri de
          informații precum și respectarea legislație în vigoare în ceea ce privește prelucrarea
          datelor și drepturile persoanelor vizate.
          <br />
          Prin intermediul platformei online sunt prelucrate următoarele date cu caracter personal:
          <ul>
            <li>Nume și prenume;</li>
            <li>Email;</li>
            <li>Telefon.</li>
          </ul>
        </div>
        <div className={styles["entire-paragraph"]}>
          <strong>{t("pages.gdpr_policy.company_legal_name")}</strong> nu prelucrează categorii de
          date cu caracter special. <strong>{t("pages.gdpr_policy.company_legal_name")}</strong> nu
          efectuează transferul de date în străinătate sau către terți.
          <br />
          Prelucrarea datelor cu caracter personal, nu are legătură cu alte sisteme de evidență.
          Activitatea propriu-zisă a firmei este aceea de a prelua comenzile inițiate de clienți
          prin intermediul platformei online, de a le stoca și prelucra în vederea facturării,
          expedierii și furnizării produselor comandate. Prelucrarea informațiilor introduse de
          client în platforma sunt procesate și stocate strict în concordanță cu scopurile pentru
          care s-a oferit consimțământul acestuia:
          <br />
          <ul>
            <li>Facturare;</li>
            <li>Livrare;</li>
            <li>
              Retragrerea dint-un conctract încheiat (retragerea se poate face conform legii, ținând
              cont de condițiile in care acest contract a fost încheia inițial și de prevederile
              legale agreate ințial).
            </li>
          </ul>
          <br />
          Scopul colectării datelor este de a factură comenzile, de a trimite corespondență și de a
          onora comenzile. Refuzul dumneavoastră de a furniza datele, determina imposibilitatea
          plasării pe acest site a comenzii dvs. și prelucrării ei, conform cerințelor, precum și
          imposibilitatea îndeplinirii scopului.
          <br />
          Conform Legii nr. 679/2016 (GDPR), utilizatorul beneficiază de dreptul de acces, dreptul
          de a fi uitat, dreptul de a porta informațiile și datele cu caracter personal, dreptul de
          intervenție asupra datelor, dreptul de a nu fi supus unei decizii individuale și dreptul
          de a se adresa justiției. Totodată, are dreptul de a se opune prelucrării datelor
          personale și poate solicită ștergerea datelor. Pentru exercitarea acestor drepturi,
          utilizatorul se poate adresa cu o cerere scrisă, datată și semnată la adresa de e-mail
          contact@sirtisa.ro. De asemenea, dacă unele din datele despre utilizatori sunt incorecte,
          avem rugămintea să ne fie semnalat acest lucru, pentru a putea efectua corecțiile
          necesare.
        </div>
      </section>
    </Zone>
  );
};

export default GdprPolicy;
