import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Zone from "../zone/Zone";

import styles from "./NotFound.module.scss";

const NotFound = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const heading = t("components.not-found.heading");
  const description = t("components.not-found.description");
  const website = t("general.website");

  return (
    <Zone>
      <div className={styles.container}>
        <div className={styles.content}>
          <h1>{heading}</h1>
          <p>
            {description}{" "}
            <a
              href={website}
              onClick={event => {
                event.preventDefault();
                navigate("/");
              }}
            >
              www.sirtisa.ro
            </a>
          </p>
        </div>
      </div>
    </Zone>
  );
};

export default NotFound;
