import React, { Fragment } from "react";
import { useLocation } from "react-router-dom";

import LogoComponent from "./LogoComponent";
import RightMenu from "./RightMenu";
import SearchBar from "./SearchBar";
import clsx from "clsx";
import MenuButton from "./MenuButton";
import HeaderBanner from "../headerBanner/HeaderBanner";

import styles from "./Navigation.module.scss";

const Navigation = () => {
  const location = useLocation();
  const shouldHideMenuButton = ["termeni-si-conditii", "politica-cookies"]
    .filter(route => {
      return location.pathname.includes(route);
    })
    .pop();

  return (
    <Fragment>
      <HeaderBanner />
      <div className={clsx(styles["navigation"], "d-flex")}>
        {!shouldHideMenuButton && <MenuButton />}
        <LogoComponent />
        <SearchBar />
        <RightMenu />
      </div>
    </Fragment>
  );
};

export default Navigation;
