import React from "react";
import styles from "./Input.module.scss";

const Input = ({ label, inputType, inputPlaceholder, name, styleClass, value, onChange }) => {
  return (
    <div>
      {label && <label>{label}</label>}
      <input
        type={inputType}
        value={value}
        placeholder={inputPlaceholder}
        className={styles[styleClass]}
        onChange={onChange}
        name={name}
      />
    </div>
  );
};

export default Input;
