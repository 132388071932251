import clsx from "clsx";
import React, { useEffect } from "react";
import menuIcon from "../../assets/icons/menu-symbol.svg";
import styles from "./MenuButton.module.scss";
import { useTranslation } from "react-i18next";
import { openOrCloseMenu } from "../../features/navigationMenuButton/menuButtonSlice";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const MenuButton = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isOpenMenuButton = useSelector(state => state.menuButton.isOpen);

  const handleIsOpenMenu = () => {
    dispatch(openOrCloseMenu(!isOpenMenuButton));
  };

  useEffect(() => {
    window.innerWidth < 768 && dispatch(openOrCloseMenu(false));
  }, [pathname]);

  return (
    <div className={clsx(styles["menu"], "align-items-center")}>
      <button onClick={() => handleIsOpenMenu()}>
        <img src={menuIcon} alt={t("placeholders.menu")} />
      </button>
    </div>
  );
};

export default MenuButton;
