import React from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import styles from "./IconText.module.scss";

const IconText = ({ icons, heading, styleClass }) => {
  const { t } = useTranslation();

  return (
    <div className={styles["container"]}>
      {heading && <h2 className={styles["icon-box-title"]}>{heading}</h2>}
      <div className={clsx(styles["icons-container"], styles[styleClass])}>
        {icons.map((iconDetails, index) => (
          <div className={styles["text-icon-box"]} key={`icon-box-${index}`}>
            <iconDetails.icon className={styles["icon"]} />
            {iconDetails.texts.map((text, index) => (
              <p
                className={clsx(styles["text"], styles[text.styleClass])}
                key={`icon-text-${index}`}
              >
                {t(text.value || text)}
              </p>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default IconText;
