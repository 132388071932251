import React from "react";
import styles from "./HeaderBanner.module.scss";
import clsx from "clsx";

import { BsCalendar3 } from "react-icons/bs";
import { HiPhone } from "react-icons/hi";
import { MdEmail } from "react-icons/md";
import { useTranslation } from "react-i18next";

const HeaderBanner = () => {
  const { t } = useTranslation();

  return (
    <div className={styles["header"]}>
      <div className={styles["header-left"]}>
        <BsCalendar3 className={styles["header-icon"]} />
        <p className={styles["header-text"]}>{t("general.schedule")}</p>
      </div>
      <div className={styles["header-right"]}>
        <span className={clsx(styles["header-phone"], styles["header-element"])}>
          <HiPhone className={styles["header-icon"]} />
          <a className={styles["header-text"]} href="tel:+40752068800">
            +40 752 068 800
          </a>
        </span>
        <span className={clsx(styles["header-email"], styles["header-element"])}>
          <MdEmail className={styles["header-icon"]} />
          <a className={styles["header-text"]} href="mailto: sirtisa@sirtisa.ro">
            sirtisa@sirtisa.ro
          </a>
        </span>
      </div>
    </div>
  );
};

export default HeaderBanner;
