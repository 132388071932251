import React from "react";
import { Link } from "react-router-dom";
import styles from "./Button.module.scss";
import clsx from "clsx";

const STYLES = ["btn-primary", "btn-bold"];
const SIZES = ["btn-mobile", "btn-medium"];

const ButtonComponent = ({ text, type, action, destination = "", styleClass, sizeClass }) => {
  const buttonStyle = STYLES.includes(styleClass) ? styleClass : STYLES[0];
  const buttonSize = SIZES.includes(sizeClass) ? sizeClass : SIZES[0];
  return (
    <Link to={destination}>
      <button
        className={clsx(styles["btn"], styles[buttonStyle], styles[buttonSize])}
        onClick={action}
        type={type}
      >
        {text}
      </button>
    </Link>
  );
};

export default ButtonComponent;
