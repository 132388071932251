import React from "react";
import { useSearchProductsQuery } from "../../features/api/sirtisaApi";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useParams } from "react-router-dom";
import Zone from "../../components/zone/Zone";
import CategoriesMenu from "../../components/lateralMenu/CategoriesMenu";
import { useTranslation } from "react-i18next";
import Products from "../../components/products/Products";
import styles from "./SearchResults.module.scss";
import LoadingSpinner from "../../components/loadingSpinner/LoadingSpinner";
import SeoComponent from "../../components/seoComponent/SeoComponent";

const SearchResults = () => {
  const { searchQuery } = useParams();
  const { t } = useTranslation();
  const { data, isLoading, error, isSuccess, isFetching } = useSearchProductsQuery(
    searchQuery ?? skipToken
  );

  return (
    <>
      <SeoComponent title={t("search_results.results_for_query", { searchQuery })} />
      <div className={styles["search-results-main-container"]}>
        <Zone>
          <div>
            <CategoriesMenu />
          </div>
          {
            error && (
              <h2 className={styles["no-data"]}>{error.error}</h2>
            ) /* Custom component should be created. Same for loading and fetching.*/
          }
          {isFetching || isLoading ? (
            <LoadingSpinner className={styles["no-data"]} />
          ) : (
            isSuccess &&
            (data.Products.length ? (
              <div className={styles["search-results-div"]}>
                <h3>{t("search_results.results_for_query", { searchQuery })}</h3>
                <Products products={data} searchPage={true} />
              </div>
            ) : (
              <h3 className={styles["no-data"]}>{t("search_results.no_results")}</h3>
            ))
          )}
        </Zone>
      </div>
    </>
  );
};

export default SearchResults;
