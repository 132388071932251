import React from "react";
import logo from "../../../src/assets/images/sirtisa_logo.png";
import styles from "./LogoComponent.module.scss";
import clsx from "clsx";
import routes from "../../app/routes/routes";
import { Link } from "react-router-dom";

const LogoComponent = () => {
  return (
    <div className={styles["logo-container"]}>
      <Link to={routes.root} className="link">
        <div className={clsx(styles["logo"], "mt-2")}>
          <img src={logo} alt="Sirtisa Logo" />
        </div>
      </Link>
    </div>
  );
};

export default LogoComponent;
