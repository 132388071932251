import React from "react";
import { Routes, Route } from "react-router";
import routes from "../routes/routes";
import Home from "../../pages/home/Home";
import About from "../../pages/about/About";
import Categories from "../../pages/categories/Categories";
import Category from "../../pages/categories/Category";
import NotFound from "../../components/notFound/NotFound";
import Contact from "../../pages/contact/Contact";
import Subcategory from "../../pages/subcategories/Subcategory";
import SearchResults from "../../pages/searchResults/SearchResults";
import TermsAndConditions from "../../pages/termsAndConditions/TermsAndConditions";
import CookiesPolicy from "../../pages/cookiesPolicy/CookiesPolicy";
import GdprPolicy from "../../pages/gdprPolicy/GdprPolicy";
import styles from "./Content.module.scss";

const Content = () => {
  return (
    <div className={styles["content"]}>
      <Routes>
        <Route path={routes.root} element={<Home />} />
        <Route path={routes.aboutUs} element={<About />} />
        <Route path={routes.contact} element={<Contact />} />
        <Route path={routes.categories.root} element={<Categories />} />
        <Route path={routes.categories.id} element={<Category />} />
        <Route path={routes.subcategories.id} element={<Subcategory />} />
        <Route path={routes.searchResults} element={<SearchResults />} />
        <Route path={routes.notFound} element={<NotFound />} />
        <Route path={routes.termsAndConditions} element={<TermsAndConditions />} />
        <Route path={routes.cookies} element={<CookiesPolicy />} />
        <Route path={routes.gdpr} element={<GdprPolicy />} />
      </Routes>
    </div>
  );
};

export default Content;
