import React from "react";
import { useTranslation } from "react-i18next";
import Zone from "../../components/zone/Zone";

import styles from "./TermsAndConditions.module.scss";
import SeoComponent from "../../components/seoComponent/SeoComponent";

const TermsAndConditions = () => {
  const { t } = useTranslation();

  return (
    <Zone styleClass="orientation-column">
      <SeoComponent
        title="Sirtisa - Termeni si conditii"
        description="Termeni și condiții de utilizare a site-ului sirtisa.ro. Consultă aici mai multe detalii!"
      />
      <header className={styles["header"]}>
        <h3>{t("pages.terms_and_conditions.headers.main_header")}</h3>
      </header>
      <section className={styles["section"]}>
        <h4>{t("pages.terms_and_conditions.headers.header_4.1")}</h4>
        <p>
          Conditii Generale pentru utilizarea site-ului
          <a
            className={styles["site-name"]}
            href="https://www.sirtisa.ro"
            target="_blank"
            rel="noopener noreferrer"
          >
            &nbsp;www.sirtisa.ro
          </a>
          .
        </p>
        <h4>{t("pages.terms_and_conditions.headers.header_4.2")}</h4>
        <p>
          Prin accesarea acestui site web si/sau a oricarei pagini a acestuia sunteti de acord cu
          aceste conditii de utilizare. Daca nu sunteti de acord cu acesti termeni si conditii de
          utilizare, nu accesati acest site.
        </p>
        <h4>{t("pages.terms_and_conditions.headers.header_4.3")}</h4>
        <p>
          Site-ul nostru va pune la dispozitie informatiile din acest site web in scop informativ
          general si nu garanteaza de exactitatea lor la un moment dat, desi se va incerca pe cat
          posibil ca la publicarea lor pe site toate informatiile sa fie exacte.
        </p>
        <h4>{t("pages.terms_and_conditions.headers.header_4.4")}</h4>
        <p>
          Prezentele Conditii Generale definesc conditiile de utilizare a site-ului
          <a className={styles["site-name"]} href="https://www.sirtisa.ro">
            &nbsp;www.sirtisa.ro&nbsp;
          </a>
          de catre potentialii vizitatori sau clienti. Accesând si navigând pe acest site, acceptati
          termenii de utilizare descrisi în continuare.
        </p>
        <h4>{t("pages.terms_and_conditions.headers.header_4.5")}</h4>
        <div className={styles["entire-paragraph"]}>
          <p>
            <strong>{t("pages.terms_and_conditions.company_legal_name")}</strong>) (denumita în
            continuare <strong>{t("pages.terms_and_conditions.company_legal_name")}</strong>), nu va
            transfera titlul de proprietate asupra aplicatiilor software.
          </p>
          <p>
            <strong>{t("pages.terms_and_conditions.company_legal_name")}</strong> detine drepturi
            complete si depline asupra titlului de proprietate si prin aceasta toate drepturile de
            autor si cele brevetate. Nu aveti dreptul sa redistribuiti, vindeti, decompilati,
            dezasamblati aplicatia software într-o forma perceptibila de catre oameni.
          </p>
          <p>
            Toate informatiile, produsele sau aplicatiile continute în acest site sunt proprietatea
            &nbsp;<strong>{t("pages.terms_and_conditions.company_legal_name")}</strong>, care îsi
            rezerva dreptul de a modifica continutul si/sau structura site-ului în orice moment si
            fara nici o informare prealabila.
          </p>
          <p>
            Întregul continut al site-ului
            <a className={styles["site-name"]} href="https://www.sirtisa.ro">
              &nbsp;www.sirtisa.ro&nbsp;
            </a>
            este protejat prin legea drepturilor de autor, toate drepturile fiind rezervate. Toate
            drepturile referitoare la pagini, continutul si prezentarea site-ului sunt detinute de
            &nbsp;<strong>{t("pages.terms_and_conditions.company_legal_name")}</strong>. Este
            interzisa copierea, modificarea, afisarea, distribuirea, transmiterea, publicarea,
            comercializarea, licentierea, crearea unor materiale derivate sau utilizarea
            continutului site-ului în orice scop fara confirmarea scrisa din partea{" "}
            <strong>{t("pages.terms_and_conditions.company_legal_name")}</strong>.
          </p>
          <p>
            Accesul si utilizarea paginii
            <a className={styles["site-name"]} href="https://www.sirtisa.ro">
              &nbsp;www.sirtisa.ro&nbsp;
            </a>
            sunt gratuite si au scopul de a veni in ajutorul utilizatorilor pentru a gasi
            informatiile necesare în cel mai usor si rapid mod posibil, conform cerintelor
            fiecaruia.
          </p>
          <p>
            Informatiile prezente pe
            <a className={styles["site-name"]} href="https://www.sirtisa.ro">
              &nbsp;www.sirtisa.ro&nbsp;
            </a>
            sunt de interes general si sunt puse la dispozitia utilizatorilor în mod gratuit. Prin
            termenul „utilizator” al acestei pagini se întelege orice persoana fizica sau juridica
            care va accesa pagina. Puteti copia si tipari continutul paginii
            <a className={styles["site-name"]} href="https://www.sirtisa.ro">
              &nbsp;www.sirtisa.ro&nbsp;
            </a>
            pentru folosinta dumneavoastra personala, fara intentii comerciale. În orice alte
            situatii, continutul
            <a className={styles["site-name"]} href="https://www.sirtisa.ro">
              &nbsp;www.sirtisa.ro&nbsp;
            </a>
            nu poate fi reprodus, modificat sau exploatat fara consimtamântul explicit al
            reprezentantilor
            <a className={styles["site-name"]} href="https://www.sirtisa.ro">
              &nbsp;www.sirtisa.ro
            </a>
            .
          </p>
        </div>
        <h4>{t("pages.terms_and_conditions.headers.header_4.6")}</h4>
        <div className={styles["entire-paragraph"]}>
          <p>
            Continutul informatiilor se refera la descrierea într-un anumit grad de detaliere a
            activitatilor, produselor si serviciilor{" "}
            <strong>{t("pages.terms_and_conditions.company_legal_name")}</strong>.{" "}
            <strong>{t("pages.terms_and_conditions.company_legal_name")}</strong> nu va acorda nici
            o garantie referitoare la:
          </p>
          <ul>
            <li> evitarea utilizarii anevoioase sau întreruperii în utilizare a site-ului;</li>
            <li> neafectarea în sens negativ a altor sisteme prin utilizarea site-ului;</li>
            <li>
              inexistenta pe site-ul pus la dispozitie a virusilor sau alte componente care ar putea
              dauna utilizatorilor.
            </li>
          </ul>
          <p>
            Astfel, <strong>{t("pages.terms_and_conditions.company_legal_name")}</strong> nu poate
            fi responsabila pentru nici un fel de daune directe sau indirecte produse prin
            utilizarea site-ului sau. Toate informatiile prezentate pe site cu privire la produsele
            <a className={styles["site-name"]} href="https://www.sirtisa.ro">
              &nbsp;www.sirtisa.ro
            </a>
            , preturi, informatii, campanii si promotii de marketing, aspecte tehnice, sunt
            prezentate cu titlu informativ. Nimic din continutul site-ului
            <a className={styles["site-name"]} href="https://www.sirtisa.ro">
              &nbsp;www.sirtisa.ro&nbsp;
            </a>
            nu poate constitui o oferta ferma de a contracta si nu poate angaja raspunderea Amigio
            în lipsa unor acorduri ulterioare.
          </p>
          <p>
            Continutul paginii web
            <a className={styles["site-name"]} href="https://www.sirtisa.ro">
              &nbsp;www.sirtisa.ro
            </a>
            , a informatiilor si a altor materiale prezentate de Comunitatea
            <a className={styles["site-name"]} href="https://www.sirtisa.ro">
              &nbsp;www.sirtisa.ro&nbsp;
            </a>
            nu reprezinta o consultatie juridica în sensul Legii nr. 51/1995.
          </p>
          <p>
            GDPR este un set de reguli bazat pe principii complexe, care este deschis la
            interpretare si cotine, de asemenea, numeroase domenii în care statele membre sunt
            invitate sa intervina. În prezent exista foarte putine îndrumari cu privire la
            implementarea GDPR si este posibil ca Autoritatile de Supraveghere sa aiba puncte de
            vedere diferite fata de cele exprimate în acest sablon. În ciuda eforturilor noastre de
            a oferi cele mai bune servicii, nu putem anticipa cu exactitate modul în care
            Autoritatea de Supraveghere sau instantele de judecata vor interpreta Regulamentul.
          </p>
          <p>
            <a className={styles["site-name"]} href="https://www.sirtisa.ro">
              &nbsp;www.sirtisa.ro&nbsp;
            </a>
            nu face nicio declaratie, promisiune sau garantie cu privire la exactitatea,
            exhaustivitatea sau caracterul adecvat al prezentului continut, al îndrumarilor si a
            consultantei, nu îsi asuma nicio obligatie de rezultat sau diligenta fata de nicio
            persoana, exclud în mod expres si neaga raspunderea pentru orice cost, pierdere sau
            daunele suferite ca urmare a utilizarii continutului.
          </p>
        </div>
        <h4>{t("pages.terms_and_conditions.headers.header_4.7")}</h4>
        <div className={styles["entire-paragraph"]}>
          <p>
            Obiectivul continutului site-ului este de a transmite informatii actualizate si exacte.
          </p>
          <p>
            <strong>{t("pages.terms_and_conditions.company_legal_name")}</strong> nu poate garanta
            ca prezentele pagini nu contin erori, si asigura ca va depune toate diligentele pentru
            realizarea unei informari corecte si remedierea eventualelor erori.
          </p>
          <p>
            Orice persoana care doreste sa-si procure unul din serviciile sau produsele prezentate
            în site este rugata sa contacteze{" "}
            <strong>{t("pages.terms_and_conditions.company_legal_name")}</strong> prin unul din
            mijloacele afisate în pagina de Contact a site-ului, pentru a se informa atât asupra
            disponibilitatii serviciului sau produsului în cauza cât si asupra conditiilor
            contractuale, tarifelor si informatiilor tehnice sau de alta natura.
          </p>
        </div>
        <h4>{t("pages.terms_and_conditions.headers.header_4.8")}</h4>
        <div className={styles["entire-paragraph"]}>
          <p>
            Când prin intermediul prezentului site va sunt solicitate informatii, aceasta are ca
            scop identificarea dumneavoastra sau posibilitatea de a va contacta. Acest lucru este cu
            atât mai necesar în cazul folosirii aplicatiilor disponibile pe site.
          </p>
          <p>
            Natura informatiilor solicitate se refera în special la date personale (nume, adresa,
            numere de telefon), adresa de e-mail, modul în care sunt utilizate sau intentioneaza sa
            fie utilizate produsele si/sau serviciile{" "}
            <strong>{t("pages.terms_and_conditions.company_legal_name")}</strong>, dar poate include
            si alte informatii aflate în strânsa legatura cu utilizarea serviciilor si/sau
            produselor solicitate.
          </p>
          <p>
            Cu scopul de a raspunde mai bine nevoilor si întrebarilor utilizatorilor site-ului,
            informatiile solicitate prin intermediul acestui site vor face obiectul unei stocari si
            prelucrari electronice.
          </p>
        </div>
        <h4>{t("pages.terms_and_conditions.headers.header_4.9")}</h4>
        <div className={styles["entire-paragraph"]}>
          <p>
            Prezentul site poate contine legaturi sau trimiteri catre alte site-uri considerate
            de&nbsp;<strong>{t("pages.terms_and_conditions.company_legal_name")}</strong> utile în
            legatura cu continutul site-ului sau si care nu se afla sub controlul sau îndrumarea sa.
            În cazul utilizarii acestor legaturi sau trimiteri se vor aplica conditiile generale de
            utilizare corespunzatoare acelor site-uri.
          </p>
          <p>
            <strong>{t("pages.terms_and_conditions.company_legal_name")}</strong> nu poate
            garanta/controla actualitatea/exactitatea informatiilor prezente pe site-urile unor
            terti, la care se face trimitere de pe site-ul sau.
          </p>
        </div>
        <h4>{t("pages.terms_and_conditions.headers.header_4.10")}</h4>
        <div className={styles["entire-paragraph"]}>
          <p>
            Orice persoana care viziteaza site-ul
            <a className={styles["site-name"]} href="https://www.sirtisa.ro">
              &nbsp;www.sirtisa.ro&nbsp;
            </a>
            si care ofera date sau informatii cu caracter personal prin intermediul acestui site îsi
            manifesta acordul în mod expres si neechivoc pentru urmatoarele: prelucrarea acestor
            date si informatii personale de catre{" "}
            <strong>{t("pages.terms_and_conditions.company_legal_name")}</strong>; transmiterii de
            materiale promotionale specifice operatiunilor de marketing direct; solutionarea de
            catre <strong>{t("pages.terms_and_conditions.company_legal_name")}</strong> a cererilor,
            întrebarilor si reclamatiilor adresate (a se vedea pagina Contact din site); alte
            activitati întreprinse de{" "}
            <strong>{t("pages.terms_and_conditions.company_legal_name")}</strong> si permise de
            lege, ce nu fac obiectul unei aprobari din partea destinatarului.
          </p>
          <p>
            <strong>{t("pages.terms_and_conditions.company_legal_name")}</strong> va pastra
            confidentialitatea acestor informatii.
          </p>
          <p>
            Utilizarea în continuare a acestui site constituie acordul expres si neechivoc al
            dumneavoastra în conformitate cu prevederile legii 677/2001 si directivelor GDPR pentru
            protectia persoanelor cu privire la prelucrarea datelor cu caracter personal si libera
            circulatie a acestor date.
          </p>
          <p>
            Pentru orice nelamurire în legatura cu exercitarea drepturilor dumneavoastra referitoare
            la utilizarea site-ului si la protectia utilizarii va rugam sa ne contactati prin
            intermediul sectiunii Contact din site.
          </p>
        </div>
      </section>
    </Zone>
  );
};

export default TermsAndConditions;
