import React, { useState, useRef, useEffect, Fragment } from "react";
import { isMobile } from "react-device-detect";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";

import Partner1 from "../../assets/images/partners/partner1.png";
import Partner2 from "../../assets/images/partners/partner5.png";
import Partner3 from "../../assets/images/partners/partner3.png";
import Partner4 from "../../assets/images/partners/partner4.png";
import Partner5 from "../../assets/images/partners/partner6.png";
import Partner6 from "../../assets/images/partners/partner2.png";
import Partner7 from "../../assets/images/partners/partner7.png";
import Partner8 from "../../assets/images/partners/partner8.png";
import Partner9 from "../../assets/images/partners/partner9.png";
import Partner10 from "../../assets/images/partners/partner10.png";
import Partner11 from "../../assets/images/partners/partner11.png";
import Partner12 from "../../assets/images/partners/partner12.png";
import Partner13 from "../../assets/images/partners/partner13.png";
import Partner14 from "../../assets/images/partners/partner14.png";
import Partner15 from "../../assets/images/partners/partner15.png";
import Partner16 from "../../assets/images/partners/partner16.png";

import styles from "./PartnersSlider.module.scss";

const initialIndexes = arrayLength => {
  return Array.from({ length: arrayLength }, (element, index) => index);
};

/**
 * Partners slider component
 *
 * @component
 */
const PartnersSlider = () => {
  const { t } = useTranslation();
  const translations = "components.partners-slider";
  let show;
  const showControlArrows = window.innerWidth > 1024;

  if (isMobile && window.innerWidth < 767) {
    show = 3;
  } else if (window.innerWidth < 1024) {
    show = 4;
  } else {
    show = 5;
  }

  const partnersData = [
    {
      src: Partner1,
      alt: t(`${translations}.partners.1`),
    },
    {
      src: Partner2,
      alt: t(`${translations}.partners.2`),
    },
    {
      src: Partner3,
      alt: t(`${translations}.partners.3`),
    },
    {
      src: Partner4,
      alt: t(`${translations}.partners.4`),
    },
    {
      src: Partner5,
      alt: t(`${translations}.partners.5`),
    },
    {
      src: Partner6,
      alt: t(`${translations}.partners.6`),
    },
    {
      src: Partner7,
      alt: t(`${translations}.partners.7`),
    },
    {
      src: Partner8,
      alt: t(`${translations}.partners.8`),
    },
    {
      src: Partner9,
      alt: t(`${translations}.partners.9`),
    },
    {
      src: Partner10,
      alt: t(`${translations}.partners.10`),
    },
    {
      src: Partner11,
      alt: t(`${translations}.partners.11`),
    },
    {
      src: Partner12,
      alt: t(`${translations}.partners.12`),
    },
    {
      src: Partner13,
      alt: t(`${translations}.partners.13`),
    },
    {
      src: Partner14,
      alt: t(`${translations}.partners.14`),
    },
    {
      src: Partner15,
      alt: t(`${translations}.partners.15`),
    },
    {
      src: Partner16,
      alt: t(`${translations}.partners.16`),
    },
  ]; //TODO: this will probably be removed

  const [currentIndexes, setCurrentIndexes] = useState(initialIndexes(show));

  const nextPartner = () => {
    if (currentIndexes[currentIndexes.length - 1] < partnersData.length - 1) {
      setCurrentIndexes(currentIndexes.map(el => ++el));
    } else {
      setCurrentIndexes(initialIndexes(show));
    }
  };

  const prevPartner = () => {
    if (currentIndexes[0] === 0) {
      const partnersLength = partnersData.length - 1;
      const newIndexes = [];

      for (let i = 0; i < show; i++) {
        newIndexes.push(partnersLength - i);
      }
      setCurrentIndexes(newIndexes.reverse());
    } else {
      setCurrentIndexes(currentIndexes.map(el => --el));
    }
  };

  const timeoutRef = useRef(null);
  const sliderTimer = 5000;

  const resetTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(() => nextPartner(), sliderTimer);

    return () => {
      resetTimeout();
    };
  }, [currentIndexes]);

  return (
    <div className={styles["container-slider"]}>
      <h2 className={styles.heading}>{t(`${translations}.heading`)}</h2>
      <div className={styles.content}>
        {partnersData.map((obj, index) => {
          return (
            <div
              className={clsx(styles.partner, currentIndexes.includes(index) && styles["active"])}
              key={`partner-${index}`}
            >
              <img loading="lazy" src={obj.src} alt={obj.alt} />
            </div>
          );
        })}
      </div>
      {showControlArrows && (
        <Fragment>
          <BsChevronLeft
            className={clsx(styles.arrow, styles["left-arrow"])}
            onClick={prevPartner}
          />
          <BsChevronRight
            className={clsx(styles.arrow, styles["right-arrow"])}
            onClick={nextPartner}
          />
        </Fragment>
      )}
    </div>
  );
};

export default PartnersSlider;
