import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import routes from "../../app/routes/routes";
import styles from "./Footer.module.scss";
import clsx from "clsx";
import facebook from "../../assets/icons/facebook.svg";
import logo from "../../assets/images/sirtisa_logo-2x.png";
import arrow from "../../assets/icons/arrow-down-circle.svg";

const Footer = () => {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();
  const [openFooterColumn, setOpenFooterColumn] = useState(null);

  const menuColumns = {
    firstColumn: "firstColumn",
    secondColumn: "secondColumn",
    thirdColumn: "thirdColumn",
  };

  const footerLinks = [
    {
      text: t("navigation.footer.terms_and_conditions"),
      destination: routes.termsAndConditions,
    },
    { text: t("navigation.footer.cookies"), destination: routes.cookies },
    { text: t("navigation.footer.gdpr"), destination: routes.gdpr },
    { text: t("navigation.footer.about"), destination: routes.aboutUs },
    { text: t("navigation.footer.contact"), destination: routes.contact },
  ];

  const handleFooterColumnOpen = openColumn => {
    setOpenFooterColumn(openColumn);
  };

  const renderFirstFooterColumn = () => (
    <div
      className={clsx("d-flex", "flex-column", "mb-2", styles["column-div"])}
      onClick={() =>
        handleFooterColumnOpen(openFooterColumn === null ? menuColumns.firstColumn : null)
      }
    >
      <div className="d-flex align-items-center justify-content-between">
        <h3>{t("navigation.footer.information")}</h3>
        <img
          src={arrow}
          alt={t("placeholders.arrow")}
          className={clsx(
            openFooterColumn === menuColumns.firstColumn && styles["transform-180"],
            "show-mobile-only"
          )}
        />
      </div>

      {footerLinks.map(footerLink => (
        <a
          key={footerLink.text}
          href={footerLink.destination}
          className={clsx(
            "link",
            "mb-3",
            openFooterColumn === menuColumns.firstColumn ? "" : "hidden-mobile"
          )}
        >
          {footerLink.text}
        </a>
      ))}
    </div>
  );

  const renderSecondFooterColumn = () => (
    <div
      className={clsx(styles["column-div"], "mb-2")}
      onClick={() =>
        handleFooterColumnOpen(openFooterColumn === null ? menuColumns.secondColumn : null)
      }
    >
      <div className="d-flex align-items-center justify-content-between">
        <h3>{t("navigation.footer.contact_details")}</h3>
        <img
          src={arrow}
          alt={t("placeholders.arrow")}
          className={clsx(
            openFooterColumn === menuColumns.secondColumn && styles["transform-180"],
            "show-mobile-only"
          )}
        />
      </div>
      <div className={openFooterColumn === menuColumns.secondColumn ? "pb-2" : "hidden-mobile"}>
        <address className="mb-3">
          <b>{t("navigation.footer.head_office")}</b>

          <p className="m-0">{t("navigation.footer.head_office_address")}</p>

          <a href="tel:+40230524809" className="link">
            +40 230 524 809
          </a>
          <br />
          <a href="tel:+40752068800" className="link">
            +40 752 068 800
          </a>
        </address>
        <address className="mb-3">
          <b>{t("navigation.footer.working_point")}</b>

          <p className="m-0">{t("navigation.footer.working_point_address")}</p>
          <p>
            <a href="tel:+40233226522" className="link">
              +40 233 226 522
            </a>
          </p>
          <p>
            <a href="tel:+40752068805" className="link">
              +40 752 068 805
            </a>
          </p>
        </address>
        <a href="mailto:sirtisa@sirtisa.ro" className="link">
          sirtisa@sirtisa.ro
        </a>
      </div>
    </div>
  );

  const renderThirdFooterColumn = () => (
    <div
      className={clsx(styles["column-div"], "mb-2")}
      onClick={() =>
        handleFooterColumnOpen(openFooterColumn === null ? menuColumns.thirdColumn : null)
      }
    >
      <div className="d-flex align-items-center justify-content-between">
        <h3>{t("navigation.footer.social_media")}</h3>
        <img
          src={arrow}
          alt={t("placeholders.arrow")}
          className={clsx(
            openFooterColumn === menuColumns.thirdColumn && styles["transform-180"],
            "show-mobile-only"
          )}
        />
      </div>
      <div
        className={clsx(
          openFooterColumn === menuColumns.thirdColumn ? "" : "hidden-mobile",
          "d-flex",
          "flex-column"
        )}
      >
        <a
          className={clsx(styles["facebook-link"], "link", "d-flex", "align-items-center", "my-3")}
          href="https://www.facebook.com/sirtisa"
          target="_blank"
          rel="noreferrer noopener"
        >
          <img
            loading="lazy"
            src={facebook}
            alt={t("navigation.footer.facebook")}
            className={styles["facebook-icon"]}
          />
          {t("navigation.footer.facebook")}
        </a>
        <img
          loading="lazy"
          src={logo}
          alt={t("general.brand")}
          className={clsx(styles["logo"], "d-flex", "align-items-center", "hidden-mobile")}
        />
      </div>
    </div>
  );

  const renderCopyright = () => (
    <p className="text-center m-0 mt-4">
      {t("navigation.footer.footer_copyright", { currentYear })}
    </p>
  );

  return (
    <footer className={clsx(styles["footer"], "p-3")}>
      <div className={clsx(styles["footer-div"], "d-flex", "justify-content-around", "mb-5")}>
        {renderFirstFooterColumn()}
        {renderSecondFooterColumn()}
        {renderThirdFooterColumn()}
      </div>
      {renderCopyright()}
    </footer>
  );
};

export default Footer;
