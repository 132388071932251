import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import Footer from "../../components/footer/Footer";
import Navigation from "../../components/navigation/Navigation";
import Content from "../Content/Content";
import BackToTop from "../../components/backToTop/BackToTop";
import CookieConsent from "react-cookie-consent";
import routes from "../routes/routes";

const Layout = () => {
  const { t } = useTranslation();
  const cookieConsentContent = [
    t("cookie_consent.text"),
    t("cookie_consent.find-in-first"),
    <Link key="cookie-consent-link" to={routes.cookies} className="link hover-color">
      {t("cookie_consent.cookie-policy")}
    </Link>,
    t("cookie_consent.find-in-last"),
  ];

  return (
    <div>
      <Navigation />
      <Content />
      <Footer />
      <BackToTop />
      <CookieConsent
        buttonText={t("cookie_consent.agree")}
        containerClasses="cookie-consent-container"
        buttonClasses="cookie-consent-button"
      >
        {cookieConsentContent}
      </CookieConsent>
    </div>
  );
};

export default Layout;
