import React from "react";
import { useLocation, Link } from "react-router-dom";
import routes from "../../app/routes/routes";
import styles from "./RightMenu.module.scss";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

const RightMenu = () => {
  const { t } = useTranslation();

  const menuItems = [
    { title: t("navigation.header.home"), destination: routes.root },
    {
      title: t("navigation.header.categories"),
      destination: routes.categories.root,
    },
    { title: t("navigation.header.about"), destination: routes.aboutUs },
    { title: t("navigation.header.contact"), destination: routes.contact },
  ];

  /**
   * Returns true a page is active
   *
   * @param {string} menuItemDestination - string containing item destination ("/categorii)
   *
   * @example
   * pathname = "/categorii/materiale"
   * menuItemDestination = "/categorii"
   *
   * pathname.split("/")[1] -> categorii
   * menuItemDestination.split("/")[1] -> categorii
   *
   * @returns {Boolean} - true if active
   */
  const isActive = menuItemDestination => {
    const { pathname } = useLocation();

    return pathname.split("/")[1] === menuItemDestination.split("/")[1];
  };

  return (
    <div className={clsx(styles["right-menu"], "d-flex")}>
      {menuItems.map((menuItem, index) => (
        <Link
          key={`${index}-${menuItem.title}`}
          to={menuItem.destination}
          className={clsx(styles["link"], isActive(menuItem.destination) ? "active" : "")}
        >
          {menuItem.title}
        </Link>
      ))}
    </div>
  );
};

export default RightMenu;
